import React from "react";

const Signup = ({ setsignup }) => {
  return (
    <div class="fixed inset-0 z-50 h-full w-full overflow-y-auto bg-gray-900 bg-opacity-60 flex justify-center items-center px-4">
      <div class="bg-gray-200 max-w-[800px] rounded-[16px] shadow-xl">
        <div class="absolute ">
          <button class="self-end p-3" onClick={() => setsignup(false)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.6719 12.5353L9.10793 7.97128L13.6433 3.43595C13.9553 3.12328 13.9566 2.61328 13.6419 2.30328C13.6379 2.29928 13.6346 2.29595 13.6306 2.29195C13.3186 1.98661 12.8159 1.99128 12.5073 2.29995L7.97193 6.83528L3.46526 2.32862C3.1546 2.01795 2.64793 2.01528 2.33726 2.32528C2.33326 2.32861 2.32926 2.33262 2.32593 2.33662C2.01526 2.64728 2.01793 3.15395 2.32859 3.46462L6.83526 7.97128L2.30059 12.5066C1.99193 12.8153 1.98726 13.3186 2.29326 13.6306C2.29726 13.6346 2.30059 13.6379 2.30459 13.6419C2.61459 13.9566 3.1246 13.9553 3.43726 13.6433L7.9726 9.10795L12.5366 13.6719C12.8473 13.9826 13.3539 13.9853 13.6646 13.6753C13.6686 13.6713 13.6719 13.6679 13.6759 13.6639C13.9853 13.3526 13.9826 12.8459 13.6719 12.5353Z" fill="white"></path>
            </svg>
          </button>
        </div>
        <div class="flex">
          <div class="w-1/3">
            <img src="images/signimage.webp" alt="" class="h-full w-full object-cover rounded-[16px]" />
          </div>
          <div class="pr-14 pl-4 pt-[40px] ltr:pl-[20px] rtl:pr-[20px]">
            <h3 class="font-semibold text-[28px] leading-[36px] md:text-[36px] md:leading-[44px] -tracking-[1px] text-[#F75F50]">
              Ready to find your Shia Muslim partner?
            </h3>
            <div class="pt-[4px]"></div>
            <p class="text-[18px] leading-[27px] sm:text-[20px] sm:leading-[30px] ">
              Find Azwaaja in your app store or scan the QR code
            </p>
            <div class="pt-[33px]"></div>
            <div class="flex flex-row gap-x-2 sm:gap-x-5 items-center justify-between">
              <a href="https://apps.apple.com/ca/app/azwaaja/id6497879200" target="_blank" rel="noopener noreferrer">
                <img src="https://cdn.muzz.com/website-v4/img/shared/apple-logo/en-US/logo.svg" class="h-auto w-[130px] sm:w-36" alt="App Store icon" loading="lazy" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.azwajaapps.azwaja" target="_blank" rel="noopener noreferrer">
                <img src="https://cdn.muzz.com/website-v4/img/shared/play-store/en-US/button.svg" class="h-auto w-36 sm:mt-0 sm:w-40" alt="Google Play icon" loading="lazy" />
              </a>
            </div>
            <div class="pt-[20px]"></div>
            <div className="flex items-center space-x-6">
              <img class="h-[150px] w-[150px] rounded-lg" src="images/qr-plainn-ios.png" alt="" />
              <img class="h-[150px] w-[150px]" src="images/logo-black.png" alt="" />
              <img class="h-[150px] w-[150px] rounded-lg" src="images/qr-plainn-android.png" alt="" />
            </div>
            <div class="pt-[20px]"></div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Signup;
