import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";


const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://backendadmin.azwaaja.com/api/events'); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredEvents = data.filter(event => event.publish === true);

        setEvents(filteredEvents);    
        } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);
  
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const formattedDate = date.toLocaleDateString(undefined, optionsDate);

    return `${formattedDate}`;
  };



  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      {/* Loading spinner or animation */}
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>; 
  }

  if (error) {
    return <div className="flex justify-center items-center h-screen">
      <div className="text-red-500">{error}</div>
    </div>; // Show an error message
  }

  return (
    <div className="bg-gray-100 relative">
      <div className="container flex items-center flex-col">
        <h1 className="text-[40px] font-bold leading-[48px] text-azwaajacolor1  -tracking-[1px] sm:text-[60px] sm:leading-[68px] sm:-tracking-[2px] text-center my-4">
          Community Events
        </h1>
        <h4 className="text-azwaajacolor2">Find your local Shia Muslim events</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-6">
          {events.length > 0 ? (
            events.map((val, i) => (
              <a href={`${val.link}`} className="col" key={i}>
                <div className="story__item">
                  <div className="story__inner">
                    <div className="story__thumb">
                      <img
                        src={`${val.image}`}
                        alt={`image`}
                        className="w-24 h-52"
                      />
                      <span className="member__activity member__activity--ofline">
                        {/* Assuming there's no `activity` field in `StorySectionContentList` */}
                      </span>
                    </div>
                    <div className="story__content">
                      <h4>{val.eventName}</h4>
                      <div className="story__content--author">
                        <div className="story__content--content">
                          <h6>By Azwaaja Team </h6>
                          <p>{formatDate(val.date)}</p>
                          <p>{val.location}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ))
          ) : (
            <p>No events found.</p>
          )}
        </div>
      </div>
      <Link to="/add-event" className="fixed bottom-4 right-4">
        <button className="bg-azwaajacolor2 text-white px-4 py-2 rounded-full shadow-lg flex items-center hover:bg-azwaajacolor1 transition-colors duration-300 focus:outline-none">
          <FaPlus className="mr-2" /> Add Event
        </button>
      </Link>
    </div>
  );
};

export default Event;
