import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ setsignup }) => {
  return (
    <footer className="w-full bg-azwaajacolor1 px-6 py-[48px] text-white md:px-[48px]">
      <div className="container mx-auto flex  flex-wrap justify-center lg:justify-between">
        <div className="flex w-full flex-col items-center lg:w-auto">
          <div className="flex flex-col items-end">
            <img
              src="images/azwajalogowhite.png"
              alt=""
              loading="lazy"
              className="h-16"
            />
          </div>

          <div className="pt-[24px]"></div>
          <p className="text-sm text-white">© 2024 Azwaaja. All rights reserved.</p>
          <div className="pt-[28px]"></div>
        </div>
        <div className="w-1/2 sm:w-1/4 lg:w-auto">
          <div className="font-extrabold tracking-wider leading-6">Company</div>
          <div className="pt-[12px]"></div>
          <div>
            <div className="break-all  hover:underline">
              <Link to={"/blog"} className="w-fit text-white">
                Blog
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
          {/* <div>
            <div className="break-all  hover:underline">
              <a target="_self" className="w-fit text-white">
                Careers
              </a>
            </div>
            <div className="pt-[4px]"></div>
          </div> */}
          {/* <div>
            <div className="break-all  hover:underline">
              <a target="_self" className="w-fit text-white">
                Press kit
              </a>
            </div>
            <div className="pt-[4px]"></div>
          </div> */}
          <div className="pt-[24px]"></div>
        </div>
        <div className="w-1/2 sm:w-1/4 lg:w-auto">
          <div className="font-bold leading-6">Product</div>
          <div className="pt-[12px]"></div>

          <div>
            <div className="break-all  hover:underline">
              <Link to="/download" className="w-fit text-white">
                Sign Up
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
        
          <div>
            <div className="break-all  hover:underline">
              <Link to="/features-list" className="w-fit text-white">
                Feature list
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
          <div>
            <div className="break-all  hover:underline">
              <Link to="/packages" className="w-fit text-white">
                Azwaaja Packages
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>

          <div className="pt-[24px]"></div>
        </div>
        <div className="w-1/2 sm:w-1/4 lg:w-auto">
          <div className="font-bold leading-6">Help &amp; Support</div>
          <div className="pt-[12px]"></div>
          <div>
            <div className="break-all  hover:underline">
              <a href="mailto:help@azwaaja.com" className="w-fit text-white">
                Help & Support
              </a>
            </div>

            <div className="pt-[4px]"></div>
          </div>
          <div className="pt-[4px]"></div>
          <div>
            <div className="break-all  hover:underline">
              <Link to={"/faq"} className="w-fit text-white">
                FAQ
              </Link>
            </div>

            <div className="pt-[4px]"></div>
          </div>


        </div>
        <div className="w-1/2 sm:w-1/4 lg:w-auto">
          <div className="font-bold leading-6">Legal</div>
          <div className="pt-[12px]"></div>

          <div>
            <div className="break-all  hover:underline">
              <Link to={"/privacy-policy"} className="w-fit text-white"

              >
                Privacy Policy
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
          <div>
            <div className="break-all  hover:underline">
              <Link to={'/features-policy'} className="w-fit text-white"

              >
                Features Terms and Conditions
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
          <div>
            <div className="break-all  hover:underline">
              <Link to={'/safety-policy'} className="w-fit text-white">
                Safety
              </Link>
            </div>
            <div className="pt-[4px]"></div>
          </div>
        </div>
      </div>
      <div className="pt-[20px] md:hidden"></div>
    </footer>
  );
};

export default Footer;
