import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const AddEventForm = () => {
    const [formData, setFormData] = useState({
        eventName: "",
        description: "",
        location: "",
        link: "",
        date: "",
        image: null
    });
    const history = useHistory();

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === "file") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Save the file object
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("eventName", formData.eventName);
        data.append("description", formData.description);
        data.append("location", formData.location);
        data.append("link", formData.link);
        data.append("date", formData.date);
        if (formData.image) {
            data.append("image", formData.image); // Append file to FormData
        }

        try {
            const response = await axios.post("https://backendadmin.azwaaja.com/api/events", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            history.push("/event");
            // Handle success (e.g., redirect to another page or show a success message)
        } catch (error) {
            console.error("Error adding event:", error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <div className="min-h-screen flex flex-col lg:flex-row justify-center items-center bg-gray-100">
            {/* Right Side - Form Section */}
            <div className="lg:w-1/2 p-8 mt-8 lg:mt-0 bg-white shadow-md rounded-lg">
                <h2 className="text-2xl font-bold text-center mb-6">Add New Event</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                            Image
                        </label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="eventName" className="block text-sm font-medium text-gray-700">
                            Title
                        </label>
                        <input
                            type="text"
                            id="eventName"
                            name="eventName"
                            value={formData.eventName}
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter event title"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            rows="4"
                            placeholder="Enter event description"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Date
                        </label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                            Location
                        </label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter event location"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="link" className="block text-sm font-medium text-gray-700">
                            Link (Optional)
                        </label>
                        <input
                            type="text"
                            id="link"
                            name="link"
                            value={formData.link}
                            onChange={handleChange}
                            className="w-full border rounded-md py-2 px-3 mt-1 text-sm focus:outline-none focus:border-blue-500"
                            placeholder="Enter event link (if any)"
                        />
                    </div>
                    <div className="flex justify-end mt-6">
                        <Link to="/event" className="mr-4">
                            <button className="text-gray-800 bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded-md transition duration-300 focus:outline-none">
                                Cancel
                            </button>
                        </Link>
                        <button
                            type="submit"
                            className="bg-azwaajacolor2 hover:bg-azwaajacolor3 text-white py-2 px-4 rounded-md transition duration-300 focus:outline-none"
                        >
                            Add Event
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEventForm;
