import React from 'react'

const WhyWork = () => {
    return (
        <div><div class="pt-[80px]">
            <h1 class="text-[40px] font-bold leading-[48px] text-azwaajacolor1  -tracking-[1px] sm:text-[60px] sm:leading-[68px] sm:-tracking-[2px] text-center">
                Why join Azwaaja?</h1>
            <p class="text-[18px] leading-[27px] sm:text-[20px] sm:leading-[30px] text-center font-semibold pt-8 pb-9 lg:pb-20">
                Join a fast-growing, Y Combinator-backed startup in London. At Azwaaja, work on a leading app with over 10 million users and take on exciting challenges that accelerate your growth.                </p>
            <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Diverse Team
                        </h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        We value unique perspectives, welcoming people from all walks of life.
                    </p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Level up quickly</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Work with talented, generous people on the kinds of challenges you’ll be proud to share.</p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Meaningful Equity
                        </h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Share in our success with a real stake in the company.
                    </p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Quarterly Hackathons</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Build something creative, compete, and enjoy a fun day with prizes.
                    </p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            International Travel</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Twice a year, we fly the team to amazing destinations.
                    </p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Regular Socials</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        From mini golf to game nights, we love bonding as a team.
                    </p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Regular socials</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Mini golf, hikes, super competitive Catan nights - we’ve done it all. Maybe you’ll be at the next social?</p>
                </div>
                <div>
                    <div class="flex items-center">
                        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="10" cy="10" r="10" fill="white">
                            </circle>
                            <path d="M10.0001 0.0234375C4.49844 0.0234375 0.0234375 4.49844 0.0234375 10.0001C0.0234375 15.5018 4.49844 19.9768 10.0001 19.9768C15.5018 19.9768 19.9768 15.5009 19.9768 10.0001C19.9768 4.49927 15.5009 0.0234375 10.0001 0.0234375ZM14.2309 8.48177L8.4926 12.8409C8.11594 13.1268 7.57927 13.0534 7.29344 12.6776L5.60927 10.4601C5.33094 10.0934 5.4026 9.57094 5.76844 9.2926C6.1351 9.01427 6.6576 9.08594 6.93594 9.45177L8.1301 11.0234L13.2226 7.15511C13.5893 6.87677 14.1118 6.94844 14.3901 7.3151C14.6693 7.68094 14.5976 8.20344 14.2309 8.48177Z" fill="#FB406C">
                            </path>
                        </svg>
                        <h3 class="text-[20px] font-semibold leading-[30px] -tracking-[0.5px]">
                            Generous Holiday</h3>
                    </div>
                    <p class="mt-1 pl-8 font-light leading-[24px] lg:text-[20px] lg:leading-[30px]">
                        Enjoy at least 33 days of holiday, wherever you are.
                    </p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default WhyWork