import { useState } from "react";

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="border-b border-border dark:border-darkmode-border">
            <button
                onClick={toggleAccordion}
                className="w-full flex justify-between items-center py-4 focus:outline-none"
            >
                <span className="font-bold  text-xl">{title}</span>
                <span className="text-4xl">{isOpen ? "-" : "+"}</span>
            </button>
            {isOpen && <p className="py-2 text-lg">{content}</p>}
        </div>
    );
};

const Accordion = ({ items }) => {
    return (
      <div className="faq mt-4">
        {items.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.question}
            content={item.answer}
          />
        ))}
      </div>
    );
};

export default Accordion;
