import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Meet Shia Singles in Your Area";
const desc =
  "If you want to meet Shia singles for companionship, friendship or even more, you have come to the right place.";

let TransportContentList = [
  {
    imgUrl: "assets/images/transport/01.jpg",
    imgAlt: "trensport thumb",
    countryName: "United kingdom",
  },
  {
    imgUrl: "assets/images/transport/02.jpg",
    imgAlt: "trensport thumb",
    countryName: "Germany",
  },
  {
    imgUrl: "assets/images/transport/03.jpg",
    imgAlt: "trensport thumb",
    countryName: "United states",
  },
  {
    imgUrl: "https://i.pinimg.com/736x/24/ba/06/24ba068ceb093840e2c9a95da1e4114c.jpg",
    imgAlt: "trensport thumb",
    countryName: "France",
  },
  {
    imgUrl: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
    imgAlt: "trensport thumb",
    countryName: "Canada",
  },
  {
    imgUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/1280px-Flag_of_Iraq.svg.png",
    imgAlt: "trensport thumb",
    countryName: "Iraq",
  },
  {
    imgUrl: "assets/images/transport/07.jpg",
    imgAlt: "trensport thumb",
    countryName: "Australia",
  },
  {
    imgUrl: "https://th.bing.com/th/id/R.7c9f6d8b26b91b34f873f62321b4f896?rik=CzbYBFt1w76a4w&riu=http%3a%2f%2fpluspng.com%2fimg-png%2firan-png-file-simplified-flag-of-iran-png-3025.png&ehk=m6SEa9OwICavX%2bhpUcwft%2fX74wZbNcJtwk1aIcND7dM%3d&risl=&pid=ImgRaw&r=0",
    imgAlt: "trensport thumb",
    countryName: "Iran",
  },
  {
    imgUrl: "assets/images/transport/09.jpg",
    imgAlt: "trensport thumb",
    countryName: "South Africa",
  },
  {
    imgUrl: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    imgAlt: "trensport thumb",
    countryName: "Pakistan",
  },
];

class TransportationSection extends Component {
  render() {
    return (
      <section className="transportation padding-top padding-bottom">
        <div className="container">
          <div className="section__wrapper">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="left">
                  <div
                    className="section__header style-2 mb-lg-0 wow fadeInUp"
                    data-wow-duration="1.5s"
                  >
                    <h2 className="text-azwaajacolor1">{title}</h2>
                    <p>{desc}</p>
                    <ul className="grid grid-cols-2">
                      {TransportContentList.map((val, i) => (
                        <li key={i} className="flex">
                          <div className="thumb">
                            {" "}
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </div>
                          <div className="content" style={{whiteSpace:"nowrap"}}>
                            <p>{val.countryName}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="right wow fadeInUp" data-wow-duration="1.5s">
                  {TransportContentList.map((val, i) => (
                    <div className="lab-line" key={i}>
                      {/* <span></span> */}
                      <div className="lab-tooltip">
                        <a>{val.countryName}</a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TransportationSection;
