const ServiceCard = ({ label, position, location }) => {
    return (
        <div className="flex flex-col items-start w-full p-6 bg-white shadow-lg rounded-2xl sm:w-[300px] sm:min-w-[300px] h-[250px] hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <div className="flex items-center justify-center w-12 h-12 bg-coral-red rounded-full">
                <img src="images/favicon.png" className="w-10 h-10 text-black" />
            </div>
            <h3 className="mt-4 text-xl font-bold text-gray-800">{label}</h3>
            <p className="mt-1 text-md font-medium text-gray-600">{position}</p>
            <p className="mt-1 text-sm text-gray-500">{location}</p>
            <a href="mailto:hr@azwaaja.com" className="mt-4 px-4 py-2 bg-azwaajacolor3  text-white rounded-full font-semibold text-md hover:bg-red-600 transition-colors duration-300 ease-in-out">
                Apply Now
            </a>
        </div>
    );
};

export default ServiceCard;
