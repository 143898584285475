import React from "react";
import { BsShieldLock, BsFillPersonFill, BsChatQuote, BsExclamationOctagon, BsCheckCircle, BsEyeSlash, BsShield, BsPersonPlus, BsClockHistory, BsFilterCircle, BsPeople } from 'react-icons/bs';

const features = [
    {
        title: "Golden Match",
        icon: <BsShieldLock className="text-3xl text-azwaajacolor1" />,
        description:
            "The Golden Match feature fosters commitment and security between matched individuals. Users opt for Golden Match to restrict chatting with other matches until deactivated, ensuring dedication and eliminating multi-conversations.",
    },
    {
        title: "Screenshot Blocker",
        icon: <BsEyeSlash className="text-3xl text-azwaajacolor1" />,
        description:
            "The Screenshot Blocker protects user privacy by preventing screenshots within the app. If attempted, the screen displays black to prevent misuse or unauthorized distribution of personal information.",
    },
    {
        title: "Add Wali/Mahram",
        icon: <BsPersonPlus className="text-3xl text-azwaajacolor1" />,
        description:
            "Female users can add a Wali (male guardian) to their profile for added security. When matched, conversations are emailed to the Wali, ensuring accountability and distinguishing serious profiles.",
    },
    {
        title: "Istikhara",
        icon: <BsChatQuote className="text-3xl text-azwaajacolor1" />,
        description:
            "Istikhara provides religious guidance before relationship decisions. Users request Istikhara, and results from an Imam are shared within 24 hours, helping informed and religiously guided decisions.",
    },
    {
        title: "Report Feature",
        icon: <BsExclamationOctagon className="text-3xl text-azwaajacolor1" />,
        description:
            "Users can report inappropriate behavior, which app administrators review for action. Reports ensure a safe environment by addressing inappropriate pictures, sexual advances, threats, or money requests.",
    },
    {
        title: "Block Feature",
        icon: <BsShield className="text-3xl text-azwaajacolor1" />,
        description:
            "Users can block others to control interactions and ensure comfort and safety. Blocking restricts matching, swiping, or any interaction with the blocked user, ensuring user control over their app experience.",
    },
    {
        title: "I.D. Verification",
        icon: <BsFillPersonFill className="text-3xl text-azwaajacolor1" />,
        description:
            "The I.D. Verification feature enhances trust with verified user badges. Verified profiles confirm identity authenticity, promoting a trustworthy community environment.",
    },
    {
        title: "Display Name Option",
        icon: <BsCheckCircle className="text-3xl text-azwaajacolor1" />,
        description:
            "Users can set a display name different from their legal name for privacy. This feature allows interaction without revealing personal information, ensuring user identity protection.",
    },
    {
        title: "Marriage Timeline Selection",
        icon: <BsClockHistory className="text-3xl text-azwaajacolor1" />,
        description:
            "Users specify marriage timelines, indicating preferred timelines or seeking Muta'h. This feature aligns marital intentions for clearer and purposeful connections.",
    },
    {
        title: "Advanced Filters",
        icon: <BsFilterCircle className="text-3xl text-azwaajacolor1" />,
        description:
            "Advanced Filters refine search criteria based on verified profiles, language, religiosity, post-marriage living, Sayyid lineage, modesty, and ethnicity, enhancing compatibility in matches.",
    },
    {
        title: "Exclusively Shia",
        icon: <BsPeople className="text-3xl text-azwaajacolor1" />,
        description:
            "Azwaaja is exclusively designed for Shia Muslims, including Ithna Asheri Muslims and minority sub-sects such as Ismailis, Zaydis, and Dawoodi Bohras, promoting inclusivity and community connection.",
    },
];

const FeaturesList = () => {
    return (
        <div className="py-20 bg-gray-100">
            <div className="container mx-auto px-4">
                <div className="max-w-3xl mx-auto">
                    <h2 className="text-3xl lg:text-4xl font-bold text-center mb-10">
                        Azwaaja App Features
                    </h2>
                    <div className="space-y-8">
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                className="bg-white rounded-lg shadow-lg p-6 lg:p-8 flex items-start space-x-6"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <div className="flex-1">
                                    <h3 className="text-xl lg:text-2xl font-semibold mb-4">
                                        {feature.title}
                                    </h3>
                                    <p className="text-gray-700">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesList;
