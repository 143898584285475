import React from "react";
import Testimonials from "../Homesections/Testinomials";

const TestinomialPage = () => {
  return (
    <div className="min-h-screen">
     
      <Testimonials />
      {/* <div>
        <div className="flex justify-between  items-center  mx-4">
          <div className="p-6 mx-auto   flex items-center  justify-between space-x-4">
            <div className="w-1/2 flex items-center justify-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/PVAyacbOgcY?si=nvOlDBD8EKl1_0Um"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="w-1/2 relative p-8">
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                className="absolute top-0 left-0 w-16 h-16 text-gray-300 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"
                ></path>
              </svg>
              <div className="text-3xl my-4 font-extrabold z-10 tracking-wide text-azwaajacolor1">
                I was looking for a well educated bride...
              </div>
              <p className="text-gray-500 w-[75%]">
                I was looking for a well educated bride with good values and
                possibly settled in the states. My matchmaker, Dua understood
                the family's preferences, she started shortlisted profiles and
                sent them to my family. Was blessed and my profile met all the
                expectations shared by Islami's family. Both the family's were
                happy with each other and based on mutual acceptance we agreed
                for further communication and meet-ups.
              </p>
              <h2 className="text-right text-3xl mr-8 font-bold z-10 tracking-wide">
                Hussein Kazim
              </h2>
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-right"
                class="svg-inline--fa fa-quote-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="absolute bottom-0 z-0 right-0 w-16 h-16 text-gray-300"
              >
                <path
                  fill="currentColor"
                  d="M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-4 flex-row-reverse items-center mx-4">
          <div className="p-6 mx-auto   flex items-center flex-row-reverse  justify-between space-x-4">
            <div className="w-1/2 flex items-center justify-center">
              <img src="images/review1.png" alt="" className="object-cover " />
            </div>
            <div className="w-1/2 relative p-8">
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                className="absolute top-0 left-0 w-16 h-16 text-gray-300 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"
                ></path>
              </svg>
              <div className="text-3xl my-4 font-extrabold z-10 tracking-wide text-azwaajacolor1">
                Finding a Righteous and Educated Partner
              </div>
              <p className="text-gray-500 w-[75%]">
                I was searching for a well-educated bride with strong Islamic
                values and preferably settled in the States. Fatima, our
                matchmaker, understood our family's preferences and started
                shortlisting profiles that aligned with our criteria.
                Alhamdulillah, my profile met all the expectations set by a
                respected Islamic family. Both families were pleased with each
                other, and with mutual acceptance, we agreed to proceed with
                further communication and meetings, seeking Allah's blessings
                for a successful union.
              </p>
              <h2 className="text-right text-3xl mr-8 font-bold z-10 tracking-wide">
                Jafar Hussein
              </h2>
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-right"
                class="svg-inline--fa fa-quote-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="absolute bottom-0 z-0 right-0 w-16 h-16 text-gray-300"
              >
                <path
                  fill="currentColor"
                  d="M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-4  items-center mx-4">
          <div className="p-6 mx-auto   flex items-center  justify-between space-x-4">
            <div className="w-1/2 flex items-center justify-center">
              <img src="images/review2.png" alt="" className=" object-cover " />
            </div>
            <div className="w-1/2 relative p-8">
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-left"
                className="absolute top-0 left-0 w-16 h-16 text-gray-300 z-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"
                ></path>
              </svg>
              <div className="text-3xl my-4 font-extrabold z-10 tracking-wide text-azwaajacolor1">
                Finding My Ideal Partner Through Azwaaja
              </div>
              <p className="text-gray-500 w-[75%]">
                I found my partner from Canada with the help of the wonderful
                matrimonial services of Azwaaja. I am working in Saudi Arabia as
                a doctor and got my equivalent match with the help of their
                extensive network. Azwaaja has a variety of registered profiles
                with verified details. The team provides many options to choose
                from, and you can truly rely on their process. Thank you,
                Azwaaja!
              </p>
              <h2 className="text-right text-3xl mr-8 font-bold z-10 tracking-wide">
                Asma Ruqayya
              </h2>
              <svg
                role="img"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="quote-right"
                class="svg-inline--fa fa-quote-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="absolute bottom-0 z-0 right-0 w-16 h-16 text-gray-300"
              >
                <path
                  fill="currentColor"
                  d="M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TestinomialPage;
