import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";


const Privacy = () => {

    const navigate = useHistory()
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const handleClick = () => {
        navigate.push("/"); // This will navigate back to the previous page in the history stack
    };

    return (
        <div className="fixed z-50 overflow-y-auto inset-0 bg-black text-white py-8 px-2 lg:px-4 select-text">
            <FaTimes
                className="absolute z-30 right-6 top-8 cursor-pointer"
                onClick={handleClick}
            />

            <main className="mx-auto container px-2 flex flex-col items-center" style={{ maxWidth: "2500px" }}>
                <Link to={"/"}>
                    <img
                        src="/images/logo.png"
                        className="block h-[80px] my-6 cursor-pointer"
                        alt="Azwaaja Logo"
                    />
                </Link>
                <h6 className="text-azwaajacolor2 text-4xl my-2">Acceptable Use Policy</h6>

                <section className="flex flex-col gap-6">
                    <p>
                        This acceptable use policy (“Policy”) sets forth the general guidelines and acceptable and prohibited uses of the azwaaja.com website (“Website”), “Azwaaja” mobile application (“Mobile Application”) and any of their related products and services (collectively, “Services”). This Policy is a legally binding agreement between you (“User”, “you” or “your”) and Propele Smart Solutions (“Propele Smart Solutions”, “we”, “us” or “our”). If you are entering into this Policy on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Policy, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Policy, you must not accept this Policy and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. You acknowledge that this Policy is a contract between you and Propele Smart Solutions, even though it is electronic and is not physically signed by you, and it governs your use of the Services.
                    </p>
                    <h6 className="text-azwaajacolor2">Prohibited activities and uses</h6>
                    <p>
                        You may not use the Services to publish content or engage in activity that is illegal under applicable law, that is harmful to others, or that would subject us to liability, including, without limitation, in connection with any of the following, each of which is prohibited under this Policy:
                    </p>
                    <ul>
                        <li>Distributing malware or other malicious code.</li>
                        <li>Disclosing sensitive personal information about others.</li>
                        <li>Collecting, or attempting to collect, personal information about third parties without their knowledge or consent.</li>
                        <li>Distributing pornography or adult related content.</li>
                        <li>Promoting or facilitating prostitution or any escort services.</li>
                        <li>Hosting, distributing or linking to child pornography or content that is harmful to minors.</li>
                        <li>Promoting or facilitating gambling, violence, terrorist activities or selling weapons or ammunition.</li>
                        <li>Engaging in the unlawful distribution of controlled substances, drug contraband or prescription medications.</li>
                        <li>Managing payment aggregators or facilitators such as processing payments on behalf of other businesses or charities.</li>
                        <li>Facilitating pyramid schemes or other models intended to seek payments from public actors.</li>
                        <li>Threatening harm to persons or property or otherwise harassing behavior.</li>
                        <li>Manual or automatic credit card or other available payment methods testing using bots or scripts.</li>
                        <li>Purchasing any of the offered Services on someone else’s behalf.</li>
                        <li>Misrepresenting or fraudulently representing products or services.</li>
                        <li>Infringing the intellectual property or other proprietary rights of others.</li>
                        <li>Facilitating, aiding, or encouraging any of the above activities through the Services.</li>
                    </ul>
                    <h6 className="text-azwaajacolor2">System abuse</h6>
                    <p>
                        Any User in violation of the Services security is subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:
                    </p>
                    <ul>
                        <li>Use or distribution of tools designed for compromising security of the Services.</li>
                        <li>Intentionally or negligently transmitting files containing a computer virus or corrupted data.</li>
                        <li>Accessing another network without permission, including to probe or scan for vulnerabilities or breach security or authentication measures.</li>
                        <li>Unauthorized scanning or monitoring of data on any network or system without proper authorization of the owner of the system or network.</li>
                    </ul>
                    <h6 className="text-azwaajacolor2">Service resources</h6>
                    <p>
                        You may not consume excessive amounts of the resources of the Services or use the Services in any way which results in performance issues or which interrupts the Services for other Users. Prohibited activities that contribute to excessive use, include without limitation:
                    </p>
                    <ul>
                        <li>Deliberate attempts to overload the Services and broadcast attacks (i.e. denial of service attacks).</li>
                        <li>Engaging in any other activities that degrade the usability and performance of the Services.</li>
                    </ul>
                    <h6 className="text-azwaajacolor2">Defamation and objectionable content</h6>
                    <p>
                        We value the freedom of expression and encourage Users to be respectful with the content they post. We are not a publisher of User content and are not in a position to investigate the veracity of individual defamation claims or to determine whether certain material, which we may find objectionable, should be censored. However, we reserve the right to moderate, disable or remove any content to prevent harm to others or to us or the Services, as determined in our sole discretion.
                    </p>
                    <h6 className="text-azwaajacolor2">Copyrighted content</h6>
                    <p>
                        Copyrighted material must not be published via the Services without the explicit permission of the copyright owner or a person explicitly authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation. However, we generally require a court order from a court of competent jurisdiction, as determined by us in our sole discretion, to take down alleged infringing material from the Services. We may terminate the Service of Users with repeated copyright infringements. Further procedures may be carried out if necessary. We will assume no liability to any User of the Services for the removal of any such material. If you believe your copyright is being infringed by a person or persons using the Services, please get in touch with us to report copyright infringement.
                    </p>
                    <h6 className="text-azwaajacolor2">Security</h6>
                    <p>
                        You take full responsibility for maintaining reasonable security precautions for your account. You are responsible for protecting and updating any login account provided to you for the Services. You must protect the confidentiality of your login details, and you should change your password periodically.
                    </p>
                    <h6 className="text-azwaajacolor2">Enforcement</h6>
                    <p>
                        We reserve our right to be the sole arbiter in determining the seriousness of each infringement and to immediately take corrective actions, including but not limited to:
                    </p>
                    <ul>
                        <li>Suspending or terminating your Service with or without notice upon any violation of this Policy. Any violations may also result in the immediate suspension or termination of your account.</li>
                        <li>Disabling or removing any content which is prohibited by this Policy, including to prevent harm to others or to us or the Services, as determined by us in our sole discretion.</li>
                        <li>Reporting violations to law enforcement as determined by us in our sole discretion.</li>
                        <li>A failure to respond to an email from our abuse team within 2 days, or as otherwise specified in the communication to you, may result in the suspension or termination of your account.</li>
                    </ul>
                    <p>
                        Suspended and terminated User accounts due to violations will not be re-activated.
                    </p>
                    <p>
                        Nothing contained in this Policy shall be construed to limit our actions or remedies in any way with respect to any of the prohibited activities. We reserve the right to take any and all additional actions we may deem appropriate with respect to such activities, including without limitation taking action to recover the costs and expenses of identifying offenders and removing them from the Services, and levying cancellation charges to cover our costs. In addition, we reserve at all times all rights and remedies available to us with respect to such activities at law or in equity.
                    </p>
                    <h6 className="text-azwaajacolor2">Reporting Violations</h6>
                    <p>
                        If you have discovered and would like to report a violation of this Policy, please contact us immediately. We will investigate the situation and provide you with full assistance.
                    </p>
                    <h6 className="text-azwaajacolor2">Changes and Amendments</h6>
                    <p>
                        We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                    </p>
                    <p>
                        An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.
                    </p>
                    <h6 className="text-azwaajacolor2">Acceptance of this policy</h6>
                    <p>
                        You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services.
                    </p>
                </section>

                <section className="self-center mx-auto flex items-center justify-center">
                    <div
                        style={{
                            borderBottom: "2px solid ",
                            borderRight: "2px solid",
                        }}
                        className="h-0 my-8 w-72 xl:w-[100vh] max-w-[523px] opacity-50"
                    />
                </section>
                <h6 className="text-azwaajacolor2 text-4xl my-2">Privacy Policy</h6>

                <section className="flex flex-col gap-6">
                    <p>
                        We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the azwaaja.com website (“Website”), “Azwaaja” mobile application (“Mobile Application”), and any of their related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.
                        <br />
                        This Policy is a legally binding agreement between you (“User”, “you” or “your”) and Propele Smart Solutions (“Propele Smart Solutions”, “we”, “us” or “our”). If you are entering into this Policy on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Policy, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Policy, you must not accept this Policy and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.
                    </p>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Collection of personal information</h6>
                        <p>
                            You can access and use the Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Services, you may be asked to provide certain Personal Information (for example, your name and e-mail address).
                        </p>
                        <p>
                            We receive and store any information you knowingly provide to us when you create an account, publish content, make a purchase, or fill any forms on the Services. When required, this information may include the following:
                        </p>
                        <ul>
                            <li>Account details (such as user name, unique user ID, password, etc)</li>
                            <li>Contact information (such as email address, phone number, etc)</li>
                            <li>Basic personal information (such as name, country of residence, etc)</li>
                            <li>Sensitive personal information (such as ethnicity, religious beliefs, mental health, etc)</li>
                            <li>Biometric information (such as facial recognition, fingerprints, etc)</li>
                            <li>Proof of identity (such as a photocopy of a government ID)</li>
                            <li>Payment information (such as credit card details, bank details, etc)</li>
                            <li>Geolocation data of your device (such as latitude and longitude)</li>
                            <li>Certain features on the mobile device (such as contacts, calendar, gallery, etc)</li>
                            <li>Any other materials you willingly submit to us (such as articles, images, feedback, etc)</li>
                        </ul>
                        <p>
                            Some of the information we collect is directly from you via the Services. However, we may also collect Personal Information about you from other sources such as social media platforms and our joint partners. Personal Information we collect from other sources may include demographic information, such as age and gender, device information, such as IP addresses, location, such as city and state, and online behavioral data, such as information about your use of social media websites, page view information and search results and links.
                        </p>
                        <p>
                            You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Services. Users who are uncertain about what information is mandatory are welcome to contact us.
                        </p>
                    </div>


                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Privacy of children</h6>
                        <p>
                            We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Services, please contact us to request that we delete that child’s Personal Information from our Services.
                        </p>
                        <p>
                            We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Policy by instructing their children never to provide Personal Information through the Services without their permission. We also ask that all parents and legal guardians overseeing the care of children take the necessary precautions to ensure that their children are instructed to never give out Personal Information when online without their permission.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Use and processing of collected information</h6>
                        <p>
                            We act as a data controller and a data processor in terms of the GDPR when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.
                        </p>
                        <p>
                            Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information and we comply with data controllers’ obligations set forth in the GDPR.
                        </p>
                        <p>
                            We act in the capacity of a data processor in situations when you submit Personal Information through the Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller in terms of the GDPR.
                        </p>
                        <p>
                            In order to make the Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                        </p>
                        <ul>
                            <li>Create and manage user accounts</li>
                            <li>Deliver products or services</li>
                            <li>Improve products and services</li>
                            <li>Run and operate the Services</li>
                        </ul>
                        <p>
                            Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; this, however, does not apply, whenever the processing of Personal Information is subject to California Consumer Privacy Act or European data protection law; (ii) provision of information is necessary for the performance of this Policy with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. We may also combine or aggregate some of your Personal Information in order to better serve you and to improve and update our Services.
                        </p>
                        <p>
                            We rely on user’s consent as a legal base as defined in the GDPR upon which we collect and process your Personal Information.
                        </p>
                        <p>
                            Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases above. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                        </p>
                        <p>
                            Payment processing: In case of Services requiring payment, you may need to provide your credit card details or other payment account information, which will be used solely for processing payments. We use third-party payment processors (“Payment Processors”) to assist us in processing your payment information securely.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Managing information</h6>
                        <p>
                            You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account on the Services.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Disclosure of information</h6>
                        <p>
                            Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our trusted subsidiaries and joint venture partners, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.
                        </p>
                        <p>
                            Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.
                        </p>
                        <p>
                            We may also disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Retention of information</h6>
                        <p>
                            We will retain and use your Personal Information for the period necessary to comply with our legal obligations, to enforce our Policy, resolve disputes, and unless a longer retention period is required or permitted by law.
                        </p>
                        <p>
                            We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Transfer of information</h6>
                        <p>
                            Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. However, this will not include countries outside the European Union and European Economic Area. If any such transfer takes place, you can find out more by checking the relevant sections of this Policy or inquire with us using the information provided in the contact section.
                        </p>
                    </div>



                    {/* Your other sections go here */}
                </section>
                <section className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2 mt-2">Data protection rights under the GDPR</h6>
                        <p>
                            If you are a resident of the European Economic Area (“EEA”), you have certain data protection rights and we aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us. In certain circumstances, you have the following data protection rights:
                        </p>
                        <ol>
                            <li>You have the right to withdraw consent where you have previously given your consent to the processing of your Personal Information. To the extent that the legal basis for our processing of your Personal Information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</li>
                            <li>You have the right to learn if your Personal Information is being processed by us, obtain disclosure regarding certain aspects of the processing, and obtain a copy of your Personal Information undergoing processing.</li>
                            <li>You have the right to verify the accuracy of your information and ask for it to be updated or corrected. You also have the right to request us to complete the Personal Information you believe is incomplete.</li>
                            <li>You have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent. Where Personal Information is processed for the public interest, in the exercise of an official authority vested in us, or for the purposes of the legitimate interests pursued by us, you may object to such processing by providing a ground related to your particular situation to justify the objection.</li>
                            <li>You have the right, under certain circumstances, to restrict the processing of your Personal Information.</li>
                            <li>You have the right, under certain circumstances, to obtain the erasure of your Personal Information from us.</li>
                            <li>You have the right to receive your Personal Information that you have provided to us in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance from us, provided that such transmission does not adversely affect the rights and freedoms of others.</li>
                            <li>You have the right to complain to a data protection authority about our collection and use of your Personal Information.</li>
                        </ol>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">California privacy rights</h6>
                        <p>
                            Consumers residing in California are afforded certain additional rights with respect to their Personal Information under the California Consumer Privacy Act (“CCPA”). If you are a California resident, this section applies to you.
                        </p>
                        <p>
                            In addition to the rights as explained in this Policy, California residents who provide Personal Information as defined in the statute to obtain Services for personal, family, or household use are entitled to request and obtain from us, once a calendar year, information about the categories and specific pieces of Personal Information we have collected and disclosed.
                        </p>
                        <p>
                            Furthermore, California residents have the right to request deletion of their Personal Information or opt-out of the sale of their Personal Information which may include selling, disclosing, or transferring Personal Information to another business or a third party for monetary or other valuable consideration. To do so, simply contact us. We will not discriminate against you if you exercise your rights under the CCPA.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">How to exercise your rights</h6>
                        <p>
                            Any requests to exercise your rights can be directed to us through the contact details provided in this document. Please note that we may ask you to verify your identity before responding to such requests. Your request must provide sufficient information that allows us to verify that you are the person you are claiming to be or that you are the authorized representative of such person. If we receive your request from an authorized representative, we may request evidence that you have provided such an authorized representative with power of attorney or that the authorized representative otherwise has valid written authority to submit requests on your behalf.
                        </p>
                        <p>
                            You must include sufficient details to allow us to properly understand the request and respond to it. We cannot respond to your request or provide you with Personal Information unless we first verify your identity or authority to make such a request and confirm that the Personal Information relates to you.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Cookies</h6>
                        <p>
                            Our Services use “cookies” to help personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                        </p>
                        <p>
                            We may use cookies to collect, store, and track information for security and personalization, and for statistical purposes. Please note that you have the ability to accept or decline cookies. Most web browsers automatically accept cookies by default, but you can modify your browser settings to decline cookies if you prefer.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Data analytics</h6>
                        <p>
                            Our Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Do Not Track signals</h6>
                        <p>
                            Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information. For a description of Do Not Track protocols for browsers and mobile devices or to learn more about the choices available to you, visit internetcookies.com
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Email marketing</h6>
                        <p>
                            We offer electronic newsletters to which you may voluntarily subscribe at any time. We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except as allowed in the information use and processing section or for the purposes of utilizing a third-party provider to send such emails. We will maintain the information sent via e-mail in accordance with applicable laws and regulations.
                        </p>
                        {/* Add more content */}
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Push notifications</h6>
                        <p>
                            We offer push notifications to which you may also voluntarily subscribe at any time. To make sure push notifications reach the correct devices, we rely on a device token unique to your device which is issued by the operating system of your device. While it is possible to access a list of device tokens, they will not reveal your identity, your unique device ID, or your contact information to us. We will maintain the information sent via e-mail in accordance with applicable laws and regulations. If, at any time, you wish to stop receiving push notifications, simply adjust your device settings accordingly.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Links to other resources</h6>
                        <p>
                            The Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Services and to read the privacy statements of each and every resource that may collect Personal Information.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Information security</h6>
                        <p>
                            We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.
                        </p>
                        {/* Add more content */}
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Data breach</h6>
                        <p>
                            In the event we become aware that the security of the Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Changes and amendments</h6>
                        <p>
                            We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                        </p>
                        {/* Add more content */}
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Acceptance of this policy</h6>
                        <p>
                            You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Contacting us
                        </h6>
                        <p>
                            If you have any questions regarding the information we may hold about you or if you wish to exercise your rights, you may use the following data subject request form to submit your request: <a href="                            https://app.websitepolicies.com/dsar/view/o5d86p7q
">Click Here</a>
                        </p>
                    </div>



                </section>
                <section className="self-center mx-auto flex items-center justify-center">
                    <div
                        style={{
                            borderBottom: "2px solid ",
                            borderRight: "2px solid",
                        }}
                        className="h-0 my-8 w-72 xl:w-[100vh] max-w-[523px] opacity-50"
                    />
                </section>
                <h6 className="text-azwaajacolor2 text-4xl my-2">Terms</h6>

                <section className="flex flex-col gap-6">
                    <p>
                        These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the azwaaja.com website (“Website”), “Azwaaja” mobile application (“Mobile Application”) and any of their related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and Propele Smart Solutions (“Propele Smart Solutions”, “we”, “us” or “our”). If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and Propele Smart Solutions, even though it is electronic and is not physically signed by you, and it governs your use of the Services.
                    </p>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Accounts and membership
                        </h6>
                        <p>

                            You must be at least 18 years of age to use the Services. By using the Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Services, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.

                        </p>
                    </div>


                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">User content</h6>
                        <p>

                            We do not own any data, information or material (collectively, “Content”) that you submit on the Services in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Services submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.

                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Billing and payments
                        </h6>
                        <p>
                            You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Accuracy of information
                        </h6>
                        <p>
                            Occasionally there may be information on the Services that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Services or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Services including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Services should be taken to indicate that all information on the Services or Services has been modified or updated.

                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Backups
                        </h6>
                        <p>
                            We are not responsible for the Content residing on the Services. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. We make no guarantee that the data you need will be available.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Links to other resources

                        </h6>
                        <p>

                            Although the Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Services. Your linking to any other off-site resources is at your own risk.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Prohibited uses
                        </h6>
                        <p>

                            In addition to other terms as set forth in the Agreement, you are prohibited from using the Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Services for violating any of the prohibited uses.                        </p>
                    </div>

                    {/* Your other sections go here */}
                </section>

                {/* middle line */}
               

                {/* Additional sections here */}

                {/* Indemnification */}
                <section className="flex flex-col gap-6">
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Intellectual property rights</h6>
                        <p>
                            “Intellectual Property Rights” means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by Propele Smart Solutions or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Propele Smart Solutions. All trademarks, service marks, graphics and logos used in connection with the Services, are trademarks or registered trademarks of Propele Smart Solutions or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Services may be the trademarks of other third parties. Your use of the Services grants you no right or license to reproduce or otherwise use any of Propele Smart Solutions or third party trademarks.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Disclaimer of warranty</h6>
                        <p>
                            You agree that such Service is provided on an “as is” and “as available” basis and that your use of the Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.

                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Limitation of liability</h6>
                        <p>
                            To the fullest extent permitted by applicable law, in no event will Propele Smart Solutions, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Propele Smart Solutions and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount no greater than one dollar or any amounts actually paid in cash by you to Propele Smart Solutions for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.
                        </p>
                    </div>

                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Indemnification</h6>
                        <p>
                            You agree to indemnify and hold Propele Smart Solutions and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Services or any willful misconduct on your part.


                        </p>
                    </div>
                    {/* Severability */}
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Severability</h6>
                        <p>
                            All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.
                        </p>
                    </div>

                    {/* Dispute resolution */}
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Dispute resolution</h6>
                        <p>
                            The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Ontario, Canada without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Canada. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Ontario, Canada, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
                        </p>
                    </div>

                    {/* Changes and amendments */}
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Changes and amendments</h6>
                        <p>
                            We reserve the right to modify this Agreement or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.

                            An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.
                        </p>
                    </div>

                    {/* Acceptance of these terms */}
                    <div className="flex flex-col gap-2">
                        <h6 className="text-azwaajacolor2">Acceptance of these terms</h6>
                        <p>
                            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Services.
                        </p>
                    </div>
                </section>


                <div className="text-sm mt-12">
                    <p className="text-center">
                        If you have any other questions, concerns, or complaints regarding this Policy, we encourage you to contact us using the details below:
                        <br />
                        hr@azwaaja.com
                        <br />
                        We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.

                    </p>

                </div>
            </main>
        </div>
    );
};

export default Privacy;
