const MainSection = () => {
  return (
    <div>
      <section className="h-screen relative flex justify-center">
        {/* <video
          className="h-full w-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >
          <source
            src="https://cdn.muzz.com/website-v4/vid/hero-video.webm"
            type="video/webm"
          />
          <source
            src="https://cdn.muzz.com/website-v4/vid/hero-video.mp4"
            type="video/mp4"
          />
        </video> */}
        <img
          src="/images/hero.jpg"
          className="w-full h-full object-cover"
          alt=""
        />
        {/* <div className="absolute top-1/2 flex max-w-[670px] -translate-y-1/2 flex-col items-center pt-[100px] sm:left-1/2 sm:-translate-x-1/2 lg:top-1/2 lg:pt-0">
          <div className="relative ml-14 h-[120px] w-[188px] sm:h-[202px] sm:w-[267px] md:h-[242px] md:w-[334px] lg:h-[190px] lg:w-[262px] xl:h-[216px] xl:w-[298px] 2xl:h-[236px] 2xl:w-[326px]">
            <img
              src="images/logo.png"
              className="h-[120px] w-auto sm:h-[202px]  md:h-[242px]  lg:h-[190px]  xl:h-[216px]  2xl:h-[236px] "
              alt="Azwaaja logo"
              loading="eager"
            />
          </div>
          <h1 className="mt-[20px] text-center text-[24px] leading-[60px] font-semibold text-white sm:text-[44px] md:text-[34px] xl:text-[40px]">
            "AND WE CREATED YOU IN PAIRS" <br /> AN-NABA 78-8
          </h1>
          <div className="pt-[24px]"></div>
          <div className="flex flex-row gap-x-3 sm:gap-x-5 justify-center items-center">
            <a
              href="https://go.muzz.com/ua1D0I3pnEb?ref=https://muzz.com/en-US/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://cdn.muzz.com/website-v4/img/shared/apple-logo/en-US/logo.svg"
                className="h-auto w-[130px] sm:w-36"
                alt="App Store icon"
                loading="lazy"
              />
            </a>
            <a
              href="https://go.muzz.com/ua1D0I3pnEb?ref=https://muzz.com/en-US/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://cdn.muzz.com/website-v4/img/shared/play-store/en-US/button.svg"
                className="h-auto w-36 sm:mt-0 sm:w-40"
                alt="Google Play icon"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="absolute bottom-5 mx-auto flex w-full flex-col items-center justify-center">
          <a href="">
            <img
              src="https://cdn.muzz.com/website-v4/img/shared/arrow.svg"
              alt="Continue to main content arrow"
              className="h-5 w-5 cursor-pointer sm:h-7 sm:w-7"
              loading="lazy"
            />
          </a>
        </div> */}
      </section>
    </div>
  );
};

export default MainSection;