import { useState } from "react";
import toast from "react-hot-toast";

const newstitle = "Newsletter Sign up";
const jointitle = "Join Community";

const SocialList = [
  {
    iconName: "fa-brands fa-x",
    visitLink: "https://x.com/azwaajaapp",
  },
  {
    iconName: "fa-brands fa-tiktok",
    visitLink: "https://www.tiktok.com/@azwaaja.app",
  },
  {
    iconName: "fa-brands fa-instagram",
    visitLink: "https://www.instagram.com/azwaaja.app",
  },
];

const NewsLetter = () => {
  const [newsEmail, setNewsEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://backendadmin.azwaaja.com/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailAddress: newsEmail }),
      });

      if (response.ok) {
        toast.success('Successfully subscribed!');

        setNewsEmail('');
      } else {
        toast.error('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <footer className="footer overflow-hidden">
      <div
        className="footer__top bg_img bg-azwaajacolor1"
      >
        <div
          className="footer__newsletter wow fadeInUp"
          data-wow-duration="1.5s"
        >
          <div className="container">
            <div className="row g-4 flex gap-y-8 items-center justify-content-center">
              <div className="col-lg-6 col-12">
                <div className="">
                  <div className="footer__newsletter--title ">
                    <h4 className="mb-4">{newstitle}</h4>
                  </div>
                  <div className="footer__newsletter--form">
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        name="email"
                        id="item01"
                        value={newsEmail}
                        onChange={(e) => setNewsEmail(e.target.value)}
                        placeholder="Your email address *"
                      />
                      <button
                        type="submit"
                        className="default-btn  text-white"
                      >
                        <span>Subscribe</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="footer__newsletter--area flex items-end justify-end flex-col">
                  <div className="footer__newsletter--title mb-2">
                    <h4 className="text-right md:text-center">{jointitle}</h4>
                  </div>
                  <div className="footer__newsletter--social">
                    <ul className="flex space-x-4">
                      {SocialList.map((val, i) => (
                        <li key={i}>
                          <a href={val.visitLink}>
                            <i className={`${val.iconName} hover:text-azwaajacolor2`}></i>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewsLetter;
