import React from "react";
import WhySection from "./WhySection";
import WhySection2 from "./WhySection2";
import WhySection3 from "./WhySection3";
import WhySection4 from "./WhySection4";

const WhyAzwaaja = () => {
  return (
    <div>
      <WhySection />
      <div class="pt-[12px] lg:pt-0"></div>
      <WhySection3 />

      <WhySection2 />
      <div class="pt-[12px] lg:pt-0"></div>
      <WhySection4 />
    </div>
  );
};

export default WhyAzwaaja;
