import React from 'react';
import { FaFileAlt, FaClipboardCheck, FaEnvelopeOpenText, FaHandshake } from 'react-icons/fa';

const steps = [
    {
        Icon: FaFileAlt,
        title: 'Submit Application',
        description: 'Send your application with CV/resume to our email.'
    },
    {
        Icon: FaClipboardCheck,
        title: 'Initial Screening',
        description: 'Our HR team will review your application and contact you for an initial screening.'
    },
    {
        Icon: FaEnvelopeOpenText,
        title: 'Interview',
        description: 'Attend an interview with our hiring manager to discuss your skills and experience.'
    },
    {
        Icon: FaHandshake,
        title: 'Offer & Onboarding',
        description: 'Receive an offer and complete the onboarding process to join our team.'
    },
];

const ApplicationSteps = () => {
    return (
        <div className="container mx-auto px-4 py-24 ">
            <h2 className="text-center text-white font-palanquin font-bold text-4xl mb-10">Application Process</h2>
            <div className="flex flex-wrap justify-center gap-10">
                {steps.map((step, index) => (
                    <div key={index} className="w-60 text-white text-center">
                        <step.Icon className="text-6xl  mb-4 mx-auto" />
                        <h3 className="text-2xl text-white font-semibold mb-2">{step.title}</h3>
                        <p>{step.description}</p>
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default ApplicationSteps;
