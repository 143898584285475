import React from "react";

const testimonials = [
  {
    name: "Hussain Kaazmi",
    testimonial: "This is an amazing service! Highly recommended.",
    image: "assets/images/allmedia/02.jpg",
    rating: 5,
  },
  {
    name: "Dua Qaadir",
    testimonial: "Incredible experience, Strong privacy and security features. ",
    image: "https://images.unsplash.com/photo-1550546094-9835463f9f71?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    rating: 5,
  },
  {
    name: "Abdullah",
    testimonial: "Fantastic quality and support. Couldn't be happier.",
    image: "assets/images/allmedia/04.jpg",
    rating: 4,
  },
];

const StarRating = ({ rating }) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <svg
          key={i}
          className="w-5 h-5 text-yellow-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.232 3.778a1 1 0 00.95.69h3.973c.969 0 1.371 1.24.588 1.81l-3.214 2.333a1 1 0 00-.364 1.118l1.231 3.778c.3.921-.755 1.688-1.54 1.118L10 13.01a1 1 0 00-1.175 0l-3.215 2.333c-.784.57-1.839-.197-1.54-1.118l1.232-3.778a1 1 0 00-.364-1.118L1.723 9.205c-.783-.57-.38-1.81.588-1.81h3.973a1 1 0 00.95-.69l1.232-3.778z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          className="w-5 h-5 text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.232 3.778a1 1 0 00.95.69h3.973c.969 0 1.371 1.24.588 1.81l-3.214 2.333a1 1 0 00-.364 1.118l1.231 3.778c.3.921-.755 1.688-1.54 1.118L10 13.01a1 1 0 00-1.175 0l-3.215 2.333c-.784.57-1.839-.197-1.54-1.118l1.232-3.778a1 1 0 00-.364-1.118L1.723 9.205c-.783-.57-.38-1.81.588-1.81h3.973a1 1 0 00.95-.69l1.232-3.778z" />
        </svg>
      );
    }
  }
  return <div className="flex justify-center">{stars}</div>;
};

const Testimonials = () => {
  return (
    <div className=" py-16 px-4">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-extrabold text-azwaajacolor1 mb-8">
          Reviews that speak volumes
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg p-8 transition-transform transform hover:scale-105"
            >
              <div className="flex justify-center mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="rounded-full w-24 object-cover h-24 border-4 border-gray-300"
                />
              </div>
              <blockquote className="text-gray-700 italic mb-4">
                "{testimonial.testimonial}"
              </blockquote>
              <StarRating rating={testimonial.rating} />
              <div className="text-center mt-4">
                <p className="font-bold text-lg text-gray-900">
                  {testimonial.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
