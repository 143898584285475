import React, { useState, useEffect } from 'react';
import WhyWork from './WhyWork';
import './Career.css';
import MainCareer from './MainCareer';
import ServiceCard from './JobCard';
import ApplicationSteps from './ApplicationSteps';

const Career = () => {
    const [jobListings, setJobListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobListings = async () => {
            try {
                // Simulate a delay
                setTimeout(() => {
                    // Dummy data
                    const data = [
                      {
                        label: "Community Engagement Specialist",
                        position: "Full-Time",
                        location: "Remote",
                      },
                      {
                        label: "Digital Marketing Specialist",
                        position: "Full-Time",
                        location: "New York, NY",
                      },
                      {
                        label: "Customer Support Specialist",
                        position: "Full-Time",
                        location: "Remote",
                      },
                      {
                        label: "Community Engagement Specialist",
                        position: "Full-Time",
                        location: "Remote",
                      },
                      {
                        label: "Digital Marketing Specialist",
                        position: "Full-Time",
                        location: "New York, NY",
                      },
                      {
                        label: "Customer Support Specialist",
                        position: "Full-Time",
                        location: "Remote",
                      },

                      // Add more job listings as needed
                    ];
                    setJobListings(data);
                    setLoading(false);
                }); // Simulate a 2 second delay
            } catch (error) {
                setError('Failed to fetch job listings');
                setLoading(false);
            }
        };

        fetchJobListings();
    }, []);

    return (
        <div>
            <div className=''>
                <MainCareer />
            </div>

            {loading && (
                <div className="flex justify-center items-center mt-8">
                    <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-blue-500"></div>
                </div>
            )}

            {error && (
                <div className="text-red-500 text-center text-lg font-medium mb-6">
                    {error}
                </div>
            )}

            <div className="p-8 mx-auto container">
                <WhyWork />
            </div>

            <div className="container mx-auto px-4 py-24" id="about">
                <h1 className="text-center font-palanquin font-bold text-7xl mb-10">Careers</h1>
                <div className="flex flex-wrap justify-center gap-10">
                    {jobListings.map((job, index) => (
                        <ServiceCard
                            key={index}
                            label={job.label}
                            position={job.position}
                            location={job.location}
                        />
                    ))}
                </div>
            </div>

            <div className='bg-azwaajacolor2'>
                <ApplicationSteps />
            </div>
        </div>
    );
};

export default Career;
