import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import axios from "axios"; // Import axios for API requests




const Catogery = () => {
  const { category } = useParams(); // Extract category from URL params
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://backendadmin.azwaaja.com/api/blogs`);
        const filteredData = response.data.filter(blog => blog.category === category);
        setBlogs(filteredData);
        setLoading(false);
      } catch (err) {
        setError("Failed to load blogs.");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [category]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, optionsDate);
  };


  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  
  if (error) {
    return (
      
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-red-600 text-2xl mb-4">Something went wrong</div>

        </div>
      </div>
    );
  }

  return (
    <section className="container mx-auto min-h-screen  mt-4 max-w-[1016px] px-4 sm:px-10 xl:px-0">
    {blogs.length === 0 ? (
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <div className="text-red-600 text-2xl mb-4">No Blogs Found for this category</div>

          </div>
        </div>
    ) :
    ( <div>
        <section className="flex flex-row flex-wrap break-all">
          <Link to={"/blog"} className="w-fit" href="">
            <span className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
              Azwaaja Blog<span>| </span>
            </span>
          </Link>
          <Link to={"/blog"} className="w-fit" href="">
            <span className="break-all pl-1 font-semibold capitalize text-azwaajacolor1 hover:text-azwaajacolor2 cursor-pointer">
              {category}
            </span>
          </Link>
        </section>
        <div className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogs.map((val, i) => (
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
                className="mb-6"
                key={i}
              >
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="blog__thumb">
                    <Link to={`/blog/${val._id}`} className="h-[250px] w-[500px]">
                      <img
                        src={`${val.image}`}
                        alt={`${"blog"}`}
                        className="w-full h-full object-cover rounded-lg hover:scale-110 transition-transform duration-300"
                      />
                    </Link>
                  </div>
                  <div className="blog__content px-3 py-4">
                    <p
                      className="mb-2 uppercase tracking-wide text-sm text-gray-500"
                    >
                      {formatDate(val.publishDate)}
                    </p>
                    <Link to={`/blog/${val._id}`}>
                      <h3 className="font-normal text-2xl mb-1 text-black">
                        {val.title}
                      </h3>
                    </Link>
                    <div>
                      <p
                        className="uppercase tracking-wide text-sm text-gray-500"
                      >
                        By Azwaaja Team
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div> )
    }
    </section>
  );
};

export default Catogery;
