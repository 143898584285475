import React from "react";

const WhySection2 = () => {
  return (
    <section class="container mx-auto flex flex-col-reverse items-center justify-center gap-x-[100px] px-4 text-center sm:px-10 lg:text-left rtl:lg:text-right xl:px-0 lg:flex-row">
      <div class="max-w-[500px]">
        <img
          class="h-full w-full object-cover object-[70%]"
          src="images/screen3.png"
          alt=""
          loading="lazy"
        />
      </div>
      <div>
        <div class="md:max-w-[500px] mx-[8px] xl:max-w-[600px] lg:rtl:text-right">
          <div class="tracking-[--0.125rem]  text-azwaajacolor1 font-bold text-[2.125rem] xl:text-[2.25rem] xl:tracking-[--0.125rem]">
          Connecting Shia Muslims
          </div>
          <div class="pt-[24px]"></div>
          <div class=" tracking-tight text-xl">
          Azwaaja is your gateway to connecting with Shia Muslims in your area, facilitating authentic and long lasting marriages. Make a profile that resonates with your interests, religiosity, and sect to find others just like you. 
          </div>
          <div class="pt-[24px]"></div>
        </div>
      </div>
    </section>
  );
};

export default WhySection2;
