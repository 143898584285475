import React from "react";
import MainSection from "../MainSection";
import Section1 from "./Section1";
import WorkSection from "../section/work";
import TransportationSection from "./Section4";
import AppSection from "./Section5";
import WhyAzwaaja from "../Why Azwaaja/WhyAzwaaja";

const Home = () => {
  return (
    <>
      <MainSection />

      <Section1 />
      <WhyAzwaaja />
      <WorkSection />
      <TransportationSection />
      <AppSection />
    </>
  );
};

export default Home;
