const Section1 = () => {
  return (
    <section id="connecting Muslims" className=" mb-[80px] px-4">
      <div className="pt-[40px] sm:pt-[72px] md:pt-[112px] lg:pt-[80px] "></div>
      <div className="max-w-[340px] sm:max-w-[560px] lg:max-w-[824px] xl:max-w-[920px] mx-auto text-center mb-5 sm:mb-12 lg:mb-8 xl:mb-12">
        <div className="text-[38px] text-azwaajacolor1 font-bold leading-[48px] -tracking-[2px] sm:text-[50px] sm:leading-[68px] sm:-tracking-[2px]">
          Connecting Shia Muslims Worldwide
        </div>
      </div>
      <div
        id="stats"
        className="flex flex-col lg:flex-row items-center lg:justify-center space-x-36 xl:mx-[20px] 2xl:mx-[40px]"
      >
        <div className="justify-self-center mx-[16px] sm:mx-[88px] xl:mx-0 md:mb-6 lg:mb-[80px] max-w-[500px] lg:max-w-[400px] xl:max-w-[600px]">
          <img
            className="lg:h-[500px] h-[330px] object-contain object-[70%] md:object-contain"
            src="images/screen1.png"
            alt=""
            loading="lazy"
          />

        </div>
        <div className="flex md:block hidden items-center justify-center mx-[16px] sm:mx-[88px] xl:mx-0 mb-6 lg:mb-[80px] max-w-[500px] lg:max-w-[400px] xl:text-[32px] xl:max-w-[600px]">
          <img
            className="lg:h-[500px] h-[330px]  object-contain object-[70%] md:object-contain"
            src="images/screen2.png"
            alt=""
            loading="lazy"
          />

        </div>
      </div>
    </section>
  );
};

export default Section1;
