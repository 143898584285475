import React from "react";

const WhySection4 = () => {
  return (
    <section class="container mx-auto flex flex-col-reverse items-center justify-center gap-x-[100px] px-4 text-center sm:px-10 lg:text-left rtl:lg:text-right xl:px-0 lg:flex-row-reverse">
      <div class="max-w-[500px]">
        <img
          class="h-[500px] w-full object-cover object-[70%]"
          src="images/screen4.png"
          alt=""
          loading="lazy"
        />
      </div>
        <div class="md:max-w-[500px] mx-[8px]  xl:max-w-[800px] lg:rtl:text-right">
        <div class="tracking-[--0.125rem] text-azwaajacolor1 font-bold text-[2.125rem] xl:text-[2.25rem] xl:tracking-[--0.125rem]">
          A Safe Haven for Shia Muslim Values
          </div>
          <div class="pt-[24px]"></div>
          <div class=" tracking-tight text-xl">
          By joining Azwaaja you become part of a community of like minded Shia Muslims that are looking for genuine connections. Whether you are Ithna Asheri, Ismaili, Zaydi or Dawoodi Bohra Shia. Azwaaja caters to all! Sign up and find your perfect match.          </div>
          <div class="pt-[24px]"></div>
        </div>
    </section>
  );
};

export default WhySection4;
