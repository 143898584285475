import { Component } from "react";
import { Link } from 'react-router-dom';

const title = "How Does it Work";

let WorkSectionContentListOne = [
    {
        imgUrl: 'images/new/2.png',
        imgAlt: 'Dating Thumb',
        title: 'Search Your Match',
        desc: 'Search your matches based on your criteria',
    },
]
let WorkSectionContentListTwo = [
    {
        imgUrl: 'images/new/3.png',
        imgAlt: 'Dating Thumb',
        title: 'Chat & Call',
        desc: 'Chat, Call, get to know your matches',
    },
]
let WorkSectionContentListThree = [
    {
        imgUrl: 'images/new/4.png',
        imgAlt: 'Dating Thumb',
        title: 'Find your Golden Match ',
        desc: 'Discover your perfect partner, your Golden Match, and embark on a new journey together. <Link to={"/packages"}> Click here </Link> to learn more about this exciting feature.',
    },
]
let WorkSectionContentListFour = [
    {
        imgUrl: 'images/new/5.png',
        imgAlt: 'Dating Thumb',
        title: 'Live The Story',
        desc: 'Create lasting memories and live your unique love story with Azwaaja.',
    },
]
let WorkSectionContentListFive = [
    {
        imgUrl: 'images/new/1.png',
        imgAlt: 'Dating Thumb',
        title: 'Make a Profile',
        desc: 'Make your profile on Azwaaja App ',
    },
]

class WorkSection extends Component {
    render() {
        return (
            <div className="work padding-top padding-bottom bg_img bg-gradient-to-r from-azwaajacolor3 to-azwaajacolor2" >
                <div className="container">
                    <div className="section__header text-center wow fadeInUp" data-wow-duration="1.5s">
                        <h2 className="text-white">{title}​</h2>
                    </div>
                    <div className="section__wrapper wow fadeInUp" data-wow-duration="1.5s">
                        <div className="d-xl-flex align-items-start work__area">
                            <div className="nav flex-xl-column nav-pills me-xl-4 work__tablist flex flex-col justify-between" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link flex active items-center p-4" id="work__tab1-tab" data-bs-toggle="pill" data-bs-target="#work__tab5" type="button" role="tab" aria-controls="work__tab5" aria-selected="true">
                                    <img src="assets/images/work/user.png" alt="dating thumb" className="me-3 w-10 h-10" />
                                    <span>Make a Profile</span>
                                </button>
                                <button className="nav-link flex items-center p-4" id="work__tab1-tab" data-bs-toggle="pill" data-bs-target="#work__tab1" type="button" role="tab" aria-controls="work__tab1" aria-selected="false">
                                    <img src="assets/images/work/01.png" alt="dating thumb" className="me-3 w-10 h-10" />
                                    <span>Search Your Match</span>
                                </button>
                                <button className="nav-link  flex items-center p-4" id="work__tab2-tab" data-bs-toggle="pill" data-bs-target="#work__tab2" type="button" role="tab" aria-controls="work__tab2" aria-selected="false">
                                    <img src="assets/images/work/02.png" alt="dating thumb" className="me-3 w-10 h-10" />
                                    <span>Chat , Call & Meet</span>
                                </button>
                                <button className="nav-link flex items-center p-4" id="work__tab3-tab" data-bs-toggle="pill" data-bs-target="#work__tab3" type="button" role="tab" aria-controls="work__tab3" aria-selected="false">
                                    <img src="assets/images/work/03.png" alt="dating thumb" className="me-3 w-10 h-10" />
                                    <span>Find your Golden Match</span>
                                </button>
                                <button className="nav-link flex items-center p-4" id="work__tab4-tab" data-bs-toggle="pill" data-bs-target="#work__tab4" type="button" role="tab" aria-controls="work__tab4" aria-selected="false">
                                    <img src="assets/images/work/04.png" alt="dating thumb" className="me-3 w-10 h-10" />
                                    <span>Live The Story</span>
                                </button>
                            </div>

                            <div className="tab-content work__tabcontent" id="v-pills-tabContent">
                                <div className="tab-pane fade " id="work__tab1" role="tabpanel" aria-labelledby="work__tab1-tab">
                                    {WorkSectionContentListOne.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner flex md:flex-row flex-col">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="work__content text-center md:text-left">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade " id="work__tab2" role="tabpanel" aria-labelledby="work__tab2-tab">
                                    {WorkSectionContentListTwo.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner flex md:flex-row flex-col">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="work__content text-center md:text-left">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="work__tab3" role="tabpanel" aria-labelledby="work__tab3-tab">
                                    {WorkSectionContentListThree.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner flex md:flex-row flex-col">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="work__content text-center md:text-left">
                                                    <h3>{val.title}</h3>
                                                    <p>Discover your perfect partner, your Golden Match, and embark on a new journey together. <Link to={"/packages"}> Click here </Link> to learn more about this exciting feature.</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade" id="work__tab4" role="tabpanel" aria-labelledby="work__tab4-tab">
                                    {WorkSectionContentListFour.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner flex md:flex-row flex-col">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="work__content text-center md:text-left">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-pane fade show active" id="work__tab5" role="tabpanel" aria-labelledby="work__tab5-tab">
                                    {WorkSectionContentListFive.map((val, i) => (
                                        <div className="work__item" key={i}>
                                            <div className="work__inner flex md:flex-row flex-col">
                                                <div className="work__thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="work__content text-center md:text-left">
                                                    <h3>{val.title}</h3>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkSection;