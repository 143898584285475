import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for API calls
import Accordion from './Accordion';

const FAQ = () => {
  const [faqItems, setFaqItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get('https://backendadmin.azwaaja.com/api/faq'); // Replace with your API endpoint
        setFaqItems(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch FAQ items');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        {/* Loading spinner or animation */}
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        {error}
      </div>
    );
  }

  if (faqItems.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        No FAQs available
      </div>
    );
  }

  return (
    <div className="container mx-auto min-h-screen px-4">
      <div className="mt-16">
        <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
        <Accordion items={faqItems} />
      </div>
    </div>
  );
};

export default FAQ;
