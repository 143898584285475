import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "swiper/css";
import "tailwindcss/tailwind.css";
import ScrollToTop from "./Libs/ScrollToTop";
import Blog from "./component/Blog/Blog";
import Catogery from "./component/Blog/Catogery/Catogery";
import SinglePage from "./component/Blog/SinglePage";
import Download from "./component/Download/Download";
import Footer from "./component/Footer";
import Home from "./component/Homesections/Home";
import NewsLetter from "./component/Homesections/Newsletter";
import Navbar from "./component/Navbar";
import NotFound from "./component/Notfound";
import Packages from "./component/Packages/Packages";
import Signup from "./component/Signup/Signup";
import FeaturesList from "./component/Terms/Featurelist";
import Featurespolicy from "./component/Terms/Featurespolicy";
import Privacy from "./component/Terms/Privacy";
import SafetyPolicy from "./component/Terms/SafetyPolicy";
import TestinomialPage from "./component/Testinomial/Testinomial";
import AddEventForm from "./component/event/AddEventForm";
import Event from "./component/event/Event";
import FAQ from "./FAQ/FAQ";
import Career from "./component/Career/Career";

function App() {
  const [signup, setsignup] = useState(false);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="relative">
        <Navbar setsignup={setsignup} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/blog" exact component={Blog} />
          <Route
            path="/blog/:id"
            render={(props) => <SinglePage {...props} />}
          />

          <Route path="/testimonials" component={TestinomialPage} />
          <Route path="/packages" component={Packages} />
          <Route path="/event" component={Event} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/features-list" component={FeaturesList} />
          <Route path="/features-policy" component={Featurespolicy} />
          <Route path="/safety-policy" component={SafetyPolicy} />
          <Route path="/blogs/:category" component={Catogery} />
          <Route path="/download" component={Download} />
          <Route path="/faq" component={FAQ} />
          <Route path="/careers" component={Career} />
          <Route path="/add-event" component={AddEventForm} />
          <Route path="*" component={NotFound} />
        </Switch>
        <NewsLetter />
        <Footer setsignup={setsignup} />
        <AnimatePresence>
          {signup ? <Signup setsignup={setsignup} /> : null}
        </AnimatePresence>
      </div>
    </BrowserRouter>
  );
}

export default App;
