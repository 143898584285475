import { Component } from "react";

const title = "Azwaaja Difference"
const subtitle =
  "Azwaaja is like a breath of fresh air. Clean and trendy design with ready to use features we are sure you will love.";

let AboutContentList = [
  {
    imgUrl: "assets/images/about/01.jpg",
    imgAlt: "Dating Thumb",
    title: "Simple To Use",
    desc: "With intuitive design anyone can become an expert at using the Azwaaja app!",
  },
  {
    imgUrl: "assets/images/about/02.jpg",
    imgAlt: "Dating Thumb",
    title: "Smart Matching",
    desc: "Advanced AI algorithm matches profiles best suited for you effortlessly",
  },
  {
    imgUrl: "assets/images/about/03.jpg",
    imgAlt: "Dating Thumb",
    title: "Advanced Filters",
    desc: "Filter by location, lineage, language, religiosity, ethnicity, and more criteria!",
  },
  {
    imgUrl: "assets/images/about/04.jpg",
    imgAlt: "Dating Thumb",
    title: "Inclusive Community",
    desc: "Azwaaja app serves Shia Ithna Asheri, Ismaili, Zaydi, Dawoodi Bohra globally.",
  },
];

class WhySection3 extends Component {
  render() {
    return (
      <div className="about padding-top padding-bottom">
        <div className="container">
          <div
            className="section__header style-2 text-center  wow fadeInUp"
            data-wow-duration="1.5s"
          >
            <h2 className=" text-azwaajacolor1  ">{title}</h2>
            <p>{subtitle}</p>
          </div>
          <div className="section__wrapper">
            <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
              {AboutContentList.map((val, i) => (
                <div
                  className="col wow fadeInUp  "
                  data-wow-duration="1.5s"
                  key={i}
                >
                  <div className="about__item text-center">
                    <div className="about__inner flex flex-col items-center ">
                      <div className="about__thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className=" hover:text-azwaajacolor2">
                        <h4 className="hover:text-azwaajacolor2 w-full mb-2" style={{ whiteSpace: "nowrap" }}>
                          {val.title}
                        </h4>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhySection3;
