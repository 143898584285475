import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";


const Featurespolicy = () => {
    
    const navigate = useHistory()
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const handleClick = () => {
        navigate.push("/"); // This will navigate back to the previous page in the history stack
    };
  return (
      <div

          className="fixed z-50 overflow-y-auto inset-0 bg-black text-white py-8 px-2 lg:px-4 select-text"
      >
          <FaTimes
              className="absolute z-30 right-6 top-8 cursor-pointer"
              onClick={handleClick}
              alt="Close"
          />

          <main className="mx-auto px-2 flex flex-col items-center" style={{ maxWidth: "2500px" }}>
              <Link to="/">
                  <img
                      src="/images/logo.png"
                      className="block h-[80px] mt-6 cursor-pointer"
                      alt="Logo"
                  />
              </Link>
              <section className="flex flex-col gap-6 container">
                  <h2 className="text-2xl font-bold text-azwaajacolor2">Features, Terms, and Policy</h2>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">1. Golden Match Feature</h3>
                      <p>
                          <strong>1.1 Description:</strong> The Golden Match feature allows users to restrict their interactions to only one matched individual, enhancing commitment and security.
                      </p>
                      <p>
                          <strong>1.2 Activation:</strong> The Golden Match will only be activated if both parties mutually opt in for the Golden Match.
                      </p>
                      <p>
                          <strong>1.3 Restrictions:</strong> Once a Golden Match is initiated, the user will not be able to chat with any other matched individuals, both existing and new, until the Golden Match is deactivated.
                      </p>
                      <p>
                          <strong>1.4 Deactivation:</strong> Either party can deactivate the Golden Match at any time, after which standard chat functionalities will be restored.
                      </p>
                      <p>
                          <strong>1.5 Purpose:</strong> This feature is designed to promote dedication and reduce the fear of users engaging in multiple conversations simultaneously.
                      </p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">2. Screenshot Blocker Feature</h3>
                      <p>
                          <strong>2.1 Description:</strong> The Screenshot Blocker feature prevents users from taking screenshots of chat conversations, ensuring privacy and security.
                      </p>
                      <p>
                          <strong>2.2 Functionality:</strong> If a screenshot attempt is made, the screen will appear black, similar to Netflix's screenshot feature.
                      </p>
                      <p>
                          <strong>2.3 Purpose:</strong> This feature encourages users to share pictures and personal information without the fear of misuse or unauthorized distribution.
                      </p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">3. Add Wali/Mahram Feature</h3>
                      <p>
                          <strong>3.1 Description:</strong> Female users can add a Wali (guardian) to their profile, who will receive transcripts of conversations with other users.
                      </p>
                      <p>
                          <strong>3.2 Information Required:</strong> Users must provide the Wali’s name, email address, and their relationship to the Wali.
                      </p>
                      <p>
                          <strong>3.3 Notification:</strong> Conversations between the user and their match will be emailed to the Wali at the end of each day.
                      </p>
                      <p>
                          <strong>3.4 Purpose:</strong> This feature differentiates serious profiles from casual ones and adds an extra layer of security and accountability.
                      </p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">4. Istikhara Feature</h3>
                      <p>
                          <strong>4.1 Description:</strong> The Istikhara feature provides a paid service for users seeking religious guidance before making significant decisions in their relationship.
                      </p>
                      <p>
                          <strong>4.2 Procedure:</strong> Users must refer to the rules of Istikhara in the knowledge section, accept the terms, and initiate the request. The other party must consent to proceed.
                      </p>
                      <p>
                          <strong>4.3 Information Sharing:</strong> Necessary information about both parties will be shared with an Imam who will conduct the Istikhara.
                      </p>
                      <p>
                          <strong>4.4 Results:</strong> The results will be shared with both parties within 24 hours.
                      </p>
                      <p>
                          <strong>4.5 Purpose:</strong> This feature supports users in making informed and religiously guided decisions.
                      </p>
                  </div>
              </section>

              {/* middle line */}
              <section className="self-center mx-auto flex items-center justify-center">
                  <div
                      style={{
                          borderBottom: "2px solid ",
                          borderRight: "2px solid",
                      }}
                      className="h-0 my-8 w-72 xl:w-[100vh] max-w-[523px] opacity-50"
                  />
              </section>
              {/* Privacy Policy */}
              <section className="flex flex-col gap-6 container">
                  <h2 className="text-2xl font-bold text-azwaajacolor2">Privacy Policy</h2>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">1. Data Collection</h3>
                      <p>
                          <strong>1.1 Personal Information:</strong> We collect personal information that users provide when creating an account, such as name, email address, and relationship details for the Wali feature.
                      </p>
                      <p>
                          <strong>1.2 Usage Data:</strong> We collect data on how users interact with the app to improve functionality and user experience.
                      </p>
                  </div>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">2. Data Usage</h3>
                      <p>
                          <strong>2.1 Communication:</strong> Personal information will be used to facilitate communication between users and their matches.
                      </p>
                      <p>
                          <strong>2.2 Features:</strong> Data will be used to implement features such as the Golden Match, Screenshot Blocker, and Add Wali/Mahram.
                      </p>
                      <p>
                          <strong>2.3 Istikhara:</strong> Information will be shared with an Imam for conducting Istikhara only with user consent.
                      </p>
                  </div>
              </section>
              <section className="self-center mx-auto flex items-center justify-center">
                  <div
                      style={{
                          borderBottom: "2px solid ",
                          borderRight: "2px solid",
                      }}
                      className="h-0 my-8 w-72 xl:w-[100vh] max-w-[523px] opacity-50"
                  />
              </section>
              {/* Report and Block Terms and Policy */}
              <section className="flex flex-col gap-6 container">
                  <h2 className="text-2xl font-bold text-azwaajacolor2">Report and Block Terms and Policy</h2>
                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">1. Report Feature</h3>
                      <p>
                          <strong>1.1 Description:</strong> The Report feature allows users to report inappropriate behavior encountered on the app.
                      </p>
                      <p>
                          <strong>1.2 Inappropriate Behavior:</strong> Inappropriate behavior includes but is not limited to:
                          Inappropriate pictures, unwanted sexual advances, threatening messages or behavior, requests for money or financial assistance, any form of harassment or abusive behavior.
                      </p>
                      <p>
                          <strong>1.3 Reporting Process:</strong> Users can report inappropriate behavior through the Report feature available in the app. The report will be sent to the app administrators for review.
                      </p>
                      <p>
                          <strong>1.4 Review and Action:</strong> Once a report is submitted, the app administrators will review the case and take appropriate action, which may include warnings, temporary suspension, or permanent banning of the offending user.
                      </p>
                      <p>
                          <strong>1.5 Confidentiality:</strong> Reports are handled with strict confidentiality, and the identity of the reporting user will not be disclosed to the offending user.
                      </p>
                      <p>
                          <strong>1.6 Personal Information:</strong> We collect personal information that users provide when creating an account, such as name, email address, and relationship details for the Wali feature.
                      </p>
                      <p>
                          <strong>1.7 Usage Data:</strong> We collect data on how users interact with the app to improve functionality and user experience.
                      </p>
                  </div>

                  <div className="flex flex-col gap-2">
                      <h3 className="text-lg font-semibold text-azwaajacolor2">2. Block Feature</h3>
                      <p>
                          <strong>2.1 Description:</strong> The Block feature allows users to block another user for any reason.
                      </p>
                      <p>
                          <strong>2.2 Blocking Process:</strong> Users can block another user directly from their profile or chat interface.
                      </p>
                      <p>
                          <strong>2.3 Consequences of Blocking:</strong>
                          <ul className="list-disc ml-6">
                              <li>Once User A blocks User B, User B cannot match or swipe on User A.</li>
                              <li>If User A and User B were matched at the time User A initiated the block, the block will automatically un-match them.</li>
                              <li>Blocked users cannot send messages, view profiles, or interact with the blocking user in any way.</li>
                          </ul>
                      </p>
                      <p>
                          <strong>2.4 Notification:</strong> The blocked user will not be notified that they have been blocked, ensuring privacy for the blocking user.
                      </p>
                      <p>
                          <strong>2.5 Reversing a Block:</strong> Users can unblock a user if they choose to, restoring the ability to match and interact as before.
                      </p>
                  </div>

              </section>
              <div className="text-sm mt-12">
                  <p className="text-center">
                      If you have any other questions, concerns, or complaints regarding this Policy, we encourage you to contact us using the details below:
                      <br />
                      hr@azwaaja.com
                      <br />
                      We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.

                  </p>

              </div>


          </main>
      </div>
  )

}

export default Featurespolicy