import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const Navbar = ({ setLang, setsignup }) => {
  const [mbnav, setMbnav] = useState(false);

  return (
    <nav
      id="navigation"
      className="w-full transition ease-in-out z-40 sticky top-0 bg-azwaajacolor1"
    // style={{
    //   backgroundImage: "linear-gradient(45deg,#8E6CF0  0%, #D69356 100%)",
    // }}
    >
      <div className="hidden lg:block">
        <div className="mx-auto flex items-center justify-between whitespace-nowrap px-8 h-[70px] xl:container">
          <div className="w-3/12">
            <div className="w-fit  mt-3">
              <Link to={"/"} className="w-fit h-16 w-auto " >
                <img
                  src="images/logo.png"
                  className="h-full w-full sm:h-12 sm:w-12"
                  alt="Azwaaja Logo"
                />
              </Link>
            </div>
          </div>
          <div className="flex gap-[40px] text-center w-fit text-white">
            <div>
              <Link to={"/"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] text-white -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Home
                </span>
              </Link>
            </div>
            <div>
              <Link to={"/packages"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] text-white -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Packages
                </span>
              </Link>
            </div>
            <div>
              <Link to={"/testimonials"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Testimonials
                </span>
              </Link>
            </div>
            <div>
              <Link to={"/blog"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Blog
                </span>
              </Link>
            </div>
            <div>
              <Link to={"/careers"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Careers
                </span>
              </Link>
            </div>
            <div>
              <Link to={"/event"} className="w-fit text-white" >
                <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                  Events
                </span>
              </Link>
            </div>
          </div>
          <div className="w-3/12 flex items-center justify-end gap-4 relative">
            {/* <button
              // onClick={setLang(true)}
              onClick={() => setLang(true)}
              className="relative flex gap-4 justify-between items-center"
            >
              <img className="h-4 rtl:ml-2 rounded-sm" src="" alt="" />
              <div className="flex items-center gap-2">
                <p className="text-[18px] leading-[27px] sm:text-[20px] sm:leading-[30px]   text-white font-semibold">
                  US
                </p>
                <svg
                  className="h-3 w-3 text-white"
                  viewBox="0 0 11 6"
                  fill="none"
                >
                  <path
                    d="M0.625 0L5.625 6L10.625 0H0.625Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button> */}
            <div>
              <button
                onClick={() => setsignup(true)}
                className="default-btn bg-[#F75F50] text-white font-semibold"
                rel="noreferrer"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden ">
        <div className="px-8 h-[70px] flex justify-between w-full items-center bg-azwaajacolor1">
          <div className="w-fit text-white">
            <Link to={"/"} className="w-fit text-white" href="">
              <img
                src="images/logo.png"
                className="h-12 w-12 sm:h-12 sm:w-12"
                alt=""
              />
            </Link>
          </div>
          <button onClick={() => setMbnav(!mbnav)}>
            <svg
              className="h-6 w-6 fill-white"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M23.8218 11.9989C23.8218 11.3459 23.2928 10.8169 22.6398 10.8169L1.35377 10.8189C0.711769 10.8189 0.183769 11.3369 0.177769 11.9799C0.177769 11.9879 0.177769 11.9949 0.177769 12.0039C0.172769 12.6529 0.704769 13.1819 1.35377 13.1829H22.6398C23.2928 13.1819 23.8218 12.6519 23.8218 11.9989Z"></path>
              <path d="M23.8218 5.83288C23.8218 5.17988 23.2928 4.65088 22.6398 4.65088L1.35377 4.65188C0.711769 4.65188 0.183769 5.16988 0.177769 5.81288C0.177769 5.82088 0.177769 5.82788 0.177769 5.83688C0.172769 6.48588 0.704769 7.01488 1.35377 7.01588H22.6398C23.2928 7.01488 23.8218 6.48588 23.8218 5.83288Z"></path>
              <path d="M23.8218 18.1659C23.8218 17.5129 23.2928 16.9839 22.6398 16.9839L1.35377 16.9849C0.711769 16.9849 0.183769 17.5029 0.177769 18.1459C0.177769 18.1539 0.177769 18.1609 0.177769 18.1699C0.172769 18.8189 0.704769 19.3479 1.35377 19.3489H22.6398C23.2928 19.3479 23.8218 18.8189 23.8218 18.1659Z"></path>
            </svg>
          </button>
          <AnimatePresence>
            {mbnav ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.2, translateY: -200 }}
                animate={{ opacity: 1, scale: 1, translateY: 0 }}
                exit={{ opacity: 0, scale: 0.2, translateY: -200 }}
                transition={{
                  duration: 0.4,
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                }}
                className="fixed z-10 h-full bg-azwaajacolor1 left-0 top-[70px] gap-[150px] flex w-full flex-col items-center justify-center  px-[30px]"
              >
                <div className="flex flex-col items-center gap-[40px]">
                  <div>
                    <Link
                      to={"/"}
                      onClick={() => setMbnav(!mbnav)}
                      className="w-fit text-black"
                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Home
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/packages"} className="w-fit text-black"
                      onClick={() => setMbnav(!mbnav)}
                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Packages
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={"/testimonials"}
                      className="w-fit text-black"
                      onClick={() => setMbnav(!mbnav)}
                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Testinomials
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={"/blog"}
                      className="w-fit text-black"
                      onClick={() => setMbnav(!mbnav)}

                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Blog
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={"/careers"}
                      className="w-fit text-black"
                      onClick={() => setMbnav(!mbnav)}

                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Careers
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link to={"/event"} className="w-fit text-black"
                      onClick={() => setMbnav(!mbnav)}
                    >
                      <span className="text-[18px] font-semibold leading-[27px] -tracking-[0.5px] hover:underline active:text-mm-hibiscus sm:text-[20px] sm:leading-[30px] text-white ">
                        Event
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="flex flex-col gap-8 items-center mb-[100px]">
                  <div className="flex justify-center, self-center, items-center ml-6">
                    <div className="flex flex-row gap-x-3 sm:gap-x-5 justify-center  items-center">
                      <a href="https://apps.apple.com/ca/app/azwaaja/id6497879200" target="_blank" rel="noopener noreferrer">
                        <img
                          src="https://cdn.muzz.com/website-v4/img/shared/apple-logo/en-US/logo.svg"
                          className="h-auto w-[130px] sm:w-36"
                          alt="App Store icon"
                          loading="lazy"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.azwajaapps.azwaja" target="_blank" rel="noopener noreferrer">
                        <img
                          src="https://cdn.muzz.com/website-v4/img/shared/play-store/en-US/button.svg"
                          className="h-auto w-36 sm:mt-0 sm:w-40"
                          alt="Google Play icon"
                          loading="lazy"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
