import React from 'react'
import { FaFemale, FaSearch } from 'react-icons/fa'
import { MdVerifiedUser } from 'react-icons/md'
import "./MainCareer.css"
import { Link } from 'react-scroll'

const MainCareer = () => {
  return (
    <div
      className="landingPage-main relative flex items-center justify-center bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: "url('images/imagehero.jpg')" }}
    >
      <div className="absolute inset-0 z-10 bg-black opacity-30"></div>

      <div className="left text-center z-20 mt-20  bg-opacity-70 p-6 rounded-lg">
        <div className="landing-desc text-white">
          <p>ARE YOU LOOKING FOR OPPURTUNITY ?</p>
          <p className="text-white">
            Join with Us <span>Azwaaja Careers</span>
          </p>
          <p>
            Take the first step towards an exciting career with Azwaaja. Explore
            our current openings and apply today to join our growing team.
          </p>
        </div>
        <div>
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="mt-4 cursor-pointer px-4 py-2 bg-azwaajacolor2 text-white rounded-full font-semibold text-md hover:bg-azwaajacolor3 transition-colors duration-300 ease-in-out"
          >
            Apply Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainCareer;

// {/* <div className="right" >
//     <div className="col1">
//         <div className="col1"></div>
//         <div className="col2">
//             {" "}
//             {/* <img src={Girl3} alt="" className="dddd" /> */}
//             <img
//                 src="signimage.webp"
//                 draggable="false"
//                 alt="girl1"
//                 className="dddd"
//             />
//             <div className="col12-icon col-icon-common">
//                 <FaFemale />
//             </div>
//         </div>
//         <div className="col3"></div>
//     </div>
//     <div className="col2">
//         <div className="col1">
//             {/* <img src={Girl2} alt="" /> */}

//             <img
//                 src="https://ik.imagekit.io/sayancr777/tr:w-400/SheRise/girl11.jpeg?updatedAt=1689504268533"
//                 draggable="false"
//                 alt="girl5"
//             />
//         </div>
//         <div className="col2">
//             <img
//                 src="https://ik.imagekit.io/sayancr777/tr:w-400/SheRise/girl15.jpg?updatedAt=1689504269740"
//                 draggable="false"
//                 alt="girl3"
//             />
//         </div>
//         <div className="col3">
//             <img
//                 src="https://ik.imagekit.io/sayancr777/tr:w-400/SheRise/girl14.jpg?updatedAt=1689504273280"
//                 draggable="false"
//                 alt="girl4"
//             />
//         </div>
//     </div>
//     <div className="col3">
//         <div className="col1">
//             <img
//                 src="https://ik.imagekit.io/sayancr777/tr:w-400/SheRise/girl2.jpg?updatedAt=1689504273895"
//                 draggable="false"
//                 alt="girl2"
//             />
//             <div className="col31-icon col-icon-common">
//                 <MdVerifiedUser />
//             </div>
//         </div>
//         <div className="col2">
//             <img
//                 src="https://ik.imagekit.io/sayancr777/tr:w-400/SheRise/girl16.jpg?updatedAt=1689504272980"
//                 draggable="false"
//                 alt="girl6"
//             />
//             <div className="col32-icon col-icon-common">
//                 <FaSearch />
//             </div>
//         </div>
//         <div className="col3"></div>
//     </div>
// </div> */}