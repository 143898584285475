import React from "react";
import { FaApple, FaGooglePlay } from 'react-icons/fa';


const subtitle = "Easy Connect To Everyone";
const title = "Download Azwaaja App";
const desc =
  "Azwaaja is the ultimate matchmaking app designed exclusively for Shia Muslims. Whether you're seeking a lifelong partner or exploring Muta'h (temporary marriage), Azwaaja offers a secure, respectful, and culturally sensitive platform to help you find your ideal match. Join the community and become one of the thousands who have found the love of their life on Azwaaja App.";

const AppSection = () => {
  return (
    <div className="app py-20 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="order-last lg:order-first">
            <div className="app__item">
              <div className="app__inner">
                <div className="app__content">
                  <h4 className="text-azwaajacolor1">{subtitle}</h4>
                  <h2 className="text-azwaajacolor1 text-3xl lg:text-4xl font-bold mb-4">
                    {title}
                  </h2>
                  <p className="text-gray-700">{desc}</p>


                  <div className="flex flex-col lg:flex-row mt-6 lg:mt-8 gap-4 lg:gap-8">
                    <a
                      href="https://apps.apple.com/ca/app/azwaaja/id6497879200"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center bg-white py-3 px-6 rounded-lg shadow-lg transition duration-300 hover:bg-gray-200"
                    >
                      <FaApple className="h-8 w-8 sm:h-10 sm:w-10 text-gray-700" />
                      <span className="ml-2 text-gray-700 font-semibold">
                        App Store
                      </span>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.azwajaapps.azwaja"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center bg-white py-3 px-6 rounded-lg shadow-lg transition duration-300 hover:bg-gray-200"
                    >
                      <FaGooglePlay className="h-8 w-8 sm:h-10 sm:w-10 text-gray-700" />
                      <span className="ml-2 text-gray-700 font-semibold">
                        Google Play
                      </span>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="order-first lg:order-last">
            <div className="app__item ">
              <div className="app__inner">
                <div className="app__thumb">
                  <img src="images/download.webp"
                    className="rounded-lg"
                    alt="no" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppSection;
