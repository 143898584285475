import React from "react";

const WhySection = () => {
  return (
    <section className="container mt-4 mx-auto flex flex-col-reverse lg:flex-row-reverse items-center justify-between gap-x-2 px-4">
      <div className="max-w-[500px] w-full lg:w-auto">
        <img
          className="h-[300px] sm:h-[400px] lg:h-[600px] w-full object-contain object-[70%]"
          src="images/whyazwaaja.png"
          alt="Screen"
          loading="lazy"
        />
      </div>
      <div className="lg:ml-8">
        <h1 className="text-[32px] sm:text-[40px] lg:text-[60px] font-bold leading-[40px] sm:leading-[48px] lg:leading-[68px] tracking-tight lg:tracking-normal text-azwaajacolor1">
          Why Azwaaja?
          <br />
          <span className="text-2xl sm:text-3xl">
            Azwaaja is the biggest Shia-Muslim Matchmaking App!
          </span>
        </h1>
        <div className="mt-4 lg:mt-6"></div>
        <div className="mx-[24px] md:mx-[160px] xl:mx-auto">
          <div className="text-lg md:text-2xl font-bold">
            Looking for genuine connections? 
          </div>
          <div className="mx-0 md:mx-[160px] xl:mx-auto mt-4">
            <div className="text-lg md:text-2xl font-bold">
              With Azwaaja you can:
            </div>
            <ul className="mt-3 mb-[20px]  md:text-lg xl:font-normal">
              <li>Find a Shia Muslim life partner nearby or worldwide.
</li>
              <li>
                Discover potential partners who share your interests and beliefs
              </li>
              <li>Relevant and powerful search filters such as (Marriage timeline, Religiosity, Lineage)
</li>
              <li>All Shia denominations, Shia Ithna Asheri, Ismaili, Zaydis and Dawoodi Bohras
</li>
            </ul>
          </div>

          <div className="mt-4 lg:mt-6"></div>
          <div className="mt-3 mb-[20px] md:text-xl xl:font-normal">
            Download Azwaaja now and unlock a world of possibilities. Available on the App Store and Google Play.

          </div>
        </div>
        <div className="flex justify-center xl:justify-start mb-[24px] md:mt-[36px] md:mb-[36px]">
          <div className="flex flex-row gap-x-3 sm:gap-x-5 justify-center items-center">
            <a href="https://apps.apple.com/ca/app/azwaaja/id6497879200" target="_blank" rel="noopener noreferrer">
              <img
                src="https://cdn.muzz.com/website-v4/img/shared/apple-logo/en-US/logo.svg"
                className="h-auto w-[130px] sm:w-36"
                alt="App Store icon"
                loading="lazy"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.azwajaapps.azwaja" target="_blank" rel="noopener noreferrer">
              <img
                src="https://cdn.muzz.com/website-v4/img/shared/play-store/en-US/button.svg"
                className="h-auto w-36 sm:w-40"
                alt="Google Play icon"
                loading="lazy"
              />
            </a>
          </div>
        </div>

        <div className="2xl:mt-[80px]"></div>
      </div>
    </section>
  );
};

export default WhySection;
