import { Component, Fragment } from "react";
import { Link } from "react-router-dom";

const title = "Our Packages";
const subtitle =
  "Azwaaja is like a breath of fresh air. Clean and trendy design with ready to use features we are sure you will love.";

let MembershipList = [
  {
    daycount: "Free",
    perMonth: "$15.00 Now And Then $30.00 Per Month.",
    price: "Free",
    btnText: "Select Plan",
    faciList: [
      { iconName: "fa-solid fa-circle-check", text: "Get Verified" },
      { iconName: "fa-solid fa-circle-check", text: "Unlimited Matches" },
      { iconName: "fa-solid fa-circle-check", text: "Unlimited Messages" },
      { iconName: "fa-solid fa-circle-check", text: "View Matches" },
      { iconName: "fa-solid fa-circle-check", text: "Send Audio Notes" },
    ],
    prices: {
      perMonth: "$0/month",
      perWeek: "$0/week",
      perYear: "$0/year",
    },
  },
  {
    daycount: "Silver",
    perMonth: "$15.00 Now And Then $30.00 Per Month.",
    price: "$15.00",
    btnText: "Select Plan",
    faciList: [
      { iconName: "fa-solid fa-circle-check", text: "Including Free Packages" },
      { iconName: "fa-solid fa-circle-check", text: "Add up to 8 Interests" },
      { iconName: "fa-solid fa-circle-check", text: "Marriage Time Line" },
      { iconName: "fa-solid fa-circle-check", text: "Modesty (Female Users)" },
      { iconName: "fa-solid fa-circle-check", text: "Post Marriage Move" },
      {
        iconName: "fa-solid fa-circle-check",
        text: "Post Marriage Living Situation",
      },
      { iconName: "fa-solid fa-circle-check", text: "Edit Languages" },
      { iconName: "fa-solid fa-circle-check", text: "Unlimited Likes" },
      { iconName: "fa-solid fa-circle-check", text: "Advanced Filters" },
    ],
    prices: {
      perMonth: "$15.99/month",
      perWeek: "$3.99/week",
      perYear: "$190/year",
    },
  },
  {
    daycount: "Gold",
    perMonth: "$15.00 Now And Then $30.00 Per Month.",
    price: "$20.00",
    btnText: "Select Plan",
    faciList: [
      {
        iconName: "fa-solid fa-circle-check",
        text: "Everything in Silver, Plus",
      },
      { iconName: "fa-solid fa-circle-check", text: "Audio Calls" },
      { iconName: "fa-solid fa-circle-check", text: "Free Powerups per Month" },
      { iconName: "fa-solid fa-circle-check", text: "Read Receipts" },
      {
        iconName: "fa-solid fa-circle-check",
        text: "Explore Users in Golden Match",
      },
    ],
    prices: {
      perMonth: "$29.99/month",
      perWeek: "$6.99/week",
      perYear: "$369/year",
    },
  },
  {
    daycount: "Platinum",
    perMonth: "$15.00 Now And Then $30.00 Per Month.",
    price: "$30.00",
    btnText: "Select Plan",
    faciList: [
      {
        iconName: "fa-solid fa-circle-check",
        text: "Everything in Gold, Plus",
      },
      { iconName: "fa-solid fa-circle-check", text: "Video Calls" },
      { iconName: "fa-solid fa-circle-check", text: "Unlimited Scrolling" },
      { iconName: "fa-solid fa-circle-check", text: "Profile Insights" },
      { iconName: "fa-solid fa-circle-check", text: "Last Active Status" },
      { iconName: "fa-solid fa-circle-check", text: "Rematch" },
    ],
    prices: {
      perMonth: "$35.99/month",
      perWeek: "$8.99/week",
      perYear: "$429.99/year",
    },
  },
];

class Packages extends Component {
  render() {
    return (
      <Fragment>
        <div className="membership padding-top padding-bottom">
          <div className="container">
            <div className="section__header style-2 text-center">
              <h2 className="text-azwaajacolor1">{title}</h2>
              <p className="text-center w-full">{subtitle}</p>
            </div>

            <div className="section__wrapper">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {MembershipList.map((val, i) => (
                  <div className="membership__item shadow-sm" key={i}>
                    <div className="membership__inner flex flex-col h-full">
                      <div className="membership__head">
                        <h4 className="text-azwaajacolor1">{val.daycount}</h4>
                      </div>
                      <div className="membership__body flex-grow">
                        <ul className="flex flex-col items-start gap-x-3">
                          {val.faciList.map((item, j) => (
                            <li key={j} className="flex items-center ">
                              <i className={item.iconName}></i>
                              <div className="text-sm text-left" style={{whiteSpace:"pre-wrap"}}>{item.text}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="membership__footer mt-auto">
                        <Link to="/download" className="default-btn reverse">
                          <span>{val.btnText}</span>
                        </Link>
                      </div>
                      {/* {val.prices && (
                        <div className="mt-4 bg-gray-100 rounded-lg shadow-md overflow-hidden">
                          <p className="text-sm text-gray-600 font-semibold p-4">Price Details:</p>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-1">
                            <div className="rounded p-3 flex flex-col items-center justify-center">
                              <p className="text-sm font-semibold mb-2">Per Week</p>
                              <p className="text-xs">{val.prices.perWeek}</p>
                            </div>
                            <div className="rounded p-3 flex flex-col items-center justify-center">
                              <p className="text-sm font-semibold mb-2">Per Month</p>
                              <p className="text-xs">{val.prices.perMonth}</p>
                            </div>
                            <div className="rounded p-3 flex flex-col items-center justify-center">
                              <p className="text-sm font-semibold mb-2">Per Year</p>
                              <p className="text-xs">{val.prices.perYear}</p>
                            </div>
                          </div>
                        </div>
                      )} */}

                    </div>

                  </div>


                ))}

              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Packages;
