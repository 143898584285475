import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";


const SafetyPolicy = () => {

    const navigate = useHistory()
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const handleClick = () => {
        navigate.push("/"); // This will navigate back to the previous page in the history stack
    };
    return (
        
        <div className="fixed z-50 overflow-y-auto inset-0 bg-black text-white py-8 px-2 lg:px-4 select-text">
            <FaTimes
                className="absolute z-30 right-6 top-8 cursor-pointer"
                onClick={handleClick}
                alt="Close"
            />

            <main className="mx-auto px-2 flex flex-col items-center" style={{ maxWidth: "2500px" }}>
                <Link to="/">
                    <img
                        src="/images/logo.png"
                        className="block h-[80px] mt-6 cursor-pointer"
                        alt="Logo"
                    />
                </Link>

                <section className="flex flex-col gap-6 justify-center items-center">
                    <h2 className="text-2xl font-bold text-azwaajacolor2 text-center md:text-4xl">Safety Policy for Azwaaja</h2>
                    <p className="text-lg text-center">
                        At Azwaaja, the safety and privacy of our users are of utmost importance. We have implemented several safety features to ensure a secure and respectful environment for all users. This policy outlines these safety features and the measures we take to protect our community.                    </p>



                    <h3 className="text-4xl font-bold text-azwaajacolor2">Safety Features</h3>

                        <h4 className="text-azwaajacolor2">1. I.D Verification</h4>
                        <p>1.1 Purpose: To enhance trust and authenticity within our community by verifying user identities.</p>
                        <p>1.2 Process: Users can voluntarily submit their identification documents for verification.</p>
                        <p>1.3 Verified Badge: Once verified, users will receive a "Verified" badge on their profile, indicating to other users that their identity has been confirmed.</p>
                        <p>1.4 Privacy: Verification documents are securely stored and not shared with other users or third parties.</p>
                        <h4 className="text-azwaajacolor2">2. Screenshot Blocker</h4>
                        <p>2.1 Purpose: To protect user privacy and prevent unauthorized sharing of personal information.</p>
                        <p>2.2 Functionality: The Screenshot Blocker feature prevents users from taking screenshots of pictures, profiles, or conversations within the app.</p>
                        <p>2.3 Implementation: Attempted screenshots will result in a black screen, ensuring that no information can be captured.</p>

                        <h4 className="text-azwaajacolor2">3. Display Name Option</h4>
                        <p>3.1 Purpose: To allow users to maintain privacy by using a display name different from their legal name.</p>
                        <p>3.2 Setup: Users can set up a display name during registration or update it at any time through their account settings.</p>
                        <p>3.3 Privacy: Legal names are kept confidential and are not displayed to other users.</p>

                        <h4 className="text-azwaajacolor2">4. Add Wali/Mahram</h4>
                        <p>4.1 Purpose: To provide an added layer of security and accountability for female users.</p>
                        <p>4.2 Functionality: Female users can add a Wali (male guardian), such as their father, to their profile.</p>
                        <p>4.3 Wali Notifications: Conversations between the user and their matches will be emailed to the Wali, ensuring transparency and security.</p>

                    <h3 className="text-4xl font-bold text-azwaajacolor2">Commitment to User Safety</h3>
                        <h4 className="text-azwaajacolor2">1. Monitoring and Reporting</h4>
                        <p>1.1 Monitoring: Our team continuously monitors the app for any inappropriate behavior or violations of our community guidelines.</p>
                        <p>1.2 Reporting: Users can report any inappropriate behavior through the Report feature. Reports are reviewed promptly, and appropriate action is taken.</p>

                        <h4 className="text-azwaajacolor2">2. Data Security</h4>
                        <p>2.1 Encryption: All user data is encrypted to protect against unauthorized access.</p>
                        <p>2.2 Access Control: Only authorized personnel have access to user data, which is used solely for providing and improving our services.</p>

                        <h4 className="text-azwaajacolor2">3. Community Guidelines</h4>
                        <p>3.1 Respect and Integrity: Users are expected to treat each other with respect and integrity. Any form of harassment, discrimination, or inappropriate behavior will not be tolerated.</p>
                        <p>3.2 Compliance: Users must comply with our terms and conditions and community guidelines to ensure a safe and respectful environment for all.</p>
                </section>




            </main>
        </div>
    );
};

export default SafetyPolicy;
